import React, { useContext, useState } from 'react';

interface PreviewState {
  previewActive: boolean;
  setPreviewActive: (newValue: boolean) => void;
  previewIsLoading: boolean;
  setPreviewIsLoading: (newValue: boolean) => void;
}

// We assume this PreviewStateContext will never be used unless inside
// the PreviewStateContext.Provider, and so the default is never used.
export const PreviewStateContext = React.createContext<PreviewState>({} as PreviewState);

export const usePreviewState = () => useContext(PreviewStateContext);

interface PreviewStateProviderProps {
  children: React.ReactNode;
}

export const PreviewStateProvider = ({ children }: PreviewStateProviderProps) => {
  const [previewActive, setPreviewActive] = useState(false);
  const [previewIsLoading, setPreviewIsLoading] = useState(false);

  return (
    <PreviewStateContext.Provider
      value={{
        previewActive,
        setPreviewActive,
        previewIsLoading,
        setPreviewIsLoading,
      }}
    >
      {children}
    </PreviewStateContext.Provider>
  );
};
