import { useGSAP } from '@gsap/react';
import BlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import gsap from 'gsap';
import React, { useRef } from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import companyLogo_green from '../../../images/companyLogo_green.svg';
import companyLogo_purple from '../../../images/companyLogo_purple.svg';
import serializers from '../../../serializers';
import * as serializersStyles from '../../../serializers.module.scss';
import { BackgroundColorTheme } from '../../../templates/Page';
import { RawPortableText } from '../../../types/types';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps } from '../../ModulesContent';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './TextAndImageModule.module.scss';

export const TextAndImageModuleFragment = graphql`
  fragment TextAndImageModule on SanityPageTextAndImageModule {
    title {
      ...LocaleStringFragment
    }
    text {
      _type
      _rawPt(resolveReferences: { maxDepth: 4 })
      _rawEn(resolveReferences: { maxDepth: 4 })
    }
    image {
      ...SanityImage
    }
    addCompanyLogoToImage
    imageOrientation
    imageSide
    backgroundColor
  }
`;

export type TextAndImageModuleProps = {
  backgroundColor: BackgroundColorTheme;
  title?: string;
  text: RawPortableText;
  image: SanityImageType;
  addCompanyLogoToImage?: boolean;
  imageOrientation: 'portrait' | 'landscape';
  imageSide: 'left' | 'right';
  className?: string;
};

function TextAndImageModule(
  props: TextAndImageModuleProps & CommonModuleProps,
): React.ReactElement {
  const {
    image,
    addCompanyLogoToImage,
    title,
    text,
    imageOrientation,
    imageSide,
    backgroundColor,
    className,
    moduleId,
  } = props;

  const containerRef = useRef<HTMLElement | null>(null);

  useGSAP(
    () => {
      const AnimationTl = gsap.timeline({
        scrollTrigger: {
          trigger: `.${styles.contentContainer}`,
          start: 'top 75%',
        } satisfies ScrollTrigger.Vars,
      });

      AnimationTl.from(`.${styles.mediaContainer}`, {
        ...(imageOrientation === 'landscape'
          ? {
              x: imageSide === 'left' ? '-100%' : '100%',
            }
          : { y: '100%' }),

        duration: 2,
        ease: 'circ.out',
      })
        .from(
          '.' + styles.title,
          {
            duration: 1,
            opacity: 0,
            y: -100,
          },
          '>-1.5',
        )
        .from(
          '.' + serializersStyles.listItem,
          {
            opacity: 0,
            y: -20,
            stagger: 0.2,
          },
          '>-0.5',
        );
    },
    { scope: containerRef },
  );

  return (
    <ModuleLayout
      id={moduleId}
      moduleRef={containerRef}
      className={clsx(
        className,
        styles.container,
        backgroundColor === '#E5F8E8' ? styles.greenBackground : styles.purpleBackground,
        imageOrientation === 'landscape' && styles.landscapeImage,
        // imageOrientation === 'portrait' && styles.portraitImage,
        imageSide === 'right' && styles.imageOnRight,
        // imageSide === 'left' && styles.imageOnLeft,
      )}
      contentClassName={styles.contentWrapper}
    >
      <div className={clsx(styles.contentContainer)}>
        <div className={clsx(styles.mediaContainer)}>
          <div className={clsx(styles.imageContainer)}>
            <Image image={image} className={styles.image} />
            {imageOrientation === 'portrait' && addCompanyLogoToImage && (
              <img
                className={styles.companyLogo}
                alt=""
                src={backgroundColor === '#E5F8E8' ? companyLogo_green : companyLogo_purple}
              />
            )}
          </div>
        </div>
        <div className={styles.textContainer}>
          {title && <h2 className={styles.title}>{title}</h2>}
          <BlockContent
            blocks={text}
            renderContainerOnSingleChild
            serializers={serializers}
            className={styles.text}
          />
        </div>
      </div>
    </ModuleLayout>
  );
}

export default TextAndImageModule;
