// extracted by mini-css-extract-plugin
export var gridList = "serializers-module--gridList--731c6";
export var line = "serializers-module--line--892bd";
export var list = "serializers-module--list--8f792";
export var listItem = "serializers-module--listItem--4480e";
export var logoContainer = "serializers-module--logoContainer--e6713";
export var logoImageContainer = "serializers-module--logoImageContainer--79f06";
export var logoTitle = "serializers-module--logoTitle--c56ff";
export var numberAndLine = "serializers-module--numberAndLine--3e3ed";
export var numberContainer = "serializers-module--numberContainer--3e903";
export var numberWrapper = "serializers-module--numberWrapper--dc0dc";
export var numberedList = "serializers-module--numberedList--9e016";
export var simpleList = "serializers-module--simpleList--e79af";
export var textContainer = "serializers-module--textContainer--a3b66";