/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useContext, useEffect, useState } from 'react';

import { t } from 'i18next';
import { RxCross1 } from 'react-icons/rx';
import { USE_COOKIES_BANNER } from '../../constants';
import { LocationContext } from '../../contexts/LocationContext';
import { useGlobalState } from '../../state/globalStateContext';
import { BackgroundColorTheme } from '../../templates/Page';
import { CalendlyEventName, pushCalendlyEvent, withDataLayer } from '../../tracking/dataLayer';
import {
  useActivateGoogleOptimize,
  useStoreReferrerOnLoad,
  useStoreUTMParamsOnLoad,
} from '../../utils/hooks';
import { clsx, urlJoin } from '../../utils/utils';
import CookiesBanner from './CookiesBanner';
import Footer, { FooterProps } from './Footer';
import Header, { HeaderProps } from './Header';
import * as styles from './Layout.module.scss';
import LocalizedLink from './LocalizedLink';

interface LayoutProps {
  pageTheme?: BackgroundColorTheme;
  children: React.ReactNode;
  headerConfig: HeaderProps;
  footerConfig: FooterProps;
  cookiesBannerConfig?: {
    textContent?: React.ReactNode;
  };
  topBanner?: {
    text: string;
    link: {
      slug: {
        current: string;
      };
    };
  };
  withOldDesign?: boolean;
}

const Layout = ({
  pageTheme,
  children,
  headerConfig,
  footerConfig,
  cookiesBannerConfig,
  topBanner,
  withOldDesign,
}: LayoutProps): React.ReactElement => {
  const isHidden = useActivateGoogleOptimize();
  const { setIsEmbedded } = useGlobalState();

  useStoreUTMParamsOnLoad();
  useStoreReferrerOnLoad();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const sanityPreviewParam = urlSearchParams.get('_sanityPreview');

    setIsEmbedded(window.parent !== window && !sanityPreviewParam);

    window.addEventListener('message', function (e) {
      // Listen for Calendly events
      const eventName: string | undefined = e?.data?.event;
      if (e.origin === 'https://calendly.com' && eventName?.startsWith('calendly.')) {
        withDataLayer(pushCalendlyEvent(eventName as CalendlyEventName));
      }
    });
  }, []);

  const [isTopBarVisible, setIsTopBarVisible] = useState(false);

  useEffect(() => {
    if (!window.localStorage.getItem('TopBarDismissed')) {
      setIsTopBarVisible(true);
    }
  }, []);

  const location = useContext(LocationContext);

  return (
    <div
      className={clsx(styles.container, withOldDesign && styles.oldDesign)}
      style={isHidden ? { opacity: 0 } : undefined}
    >
      <Header {...headerConfig}></Header>
      {topBanner &&
        isTopBarVisible &&
        !location?.pathname.endsWith(urlJoin('insight', topBanner.link.slug.current)) && (
          <div className={styles.topBannerContainer}>
            <div className={styles.topBannerContent}>
              <div className={styles.topBannerTextContainer}>
                <p className={styles.topBannerText}>{topBanner.text}</p>
                <LocalizedLink
                  to={urlJoin('insight', topBanner.link.slug.current)}
                  className={styles.topBannerLink}
                >
                  {t('know_more', 'Saiba mais')}
                </LocalizedLink>
              </div>
              <RxCross1
                className={styles.topBannerCloseIcon}
                onClick={() => {
                  window.localStorage.setItem('TopBarDismissed', 'true');
                  setIsTopBarVisible(false);
                }}
              />
            </div>
          </div>
        )}
      <div role="main" className={clsx(styles.main)} data-theme={'light'}>
        {children}
      </div>
      <Footer {...footerConfig}></Footer>
      {USE_COOKIES_BANNER && cookiesBannerConfig?.textContent && (
        <CookiesBanner textContent={cookiesBannerConfig.textContent} />
      )}
    </div>
  );
};

export default Layout;
