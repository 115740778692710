import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import * as styles from './PreviewLoadingScreen.module.scss';

function PreviewLoadingScreen() {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <FaSpinner className={styles.icon} />
        <h1 className={styles.title}>Loading Preview...</h1>
      </div>
    </div>
  );
}

export default PreviewLoadingScreen;
