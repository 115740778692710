exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-alternative-fund-tsx": () => import("./../../../src/templates/AlternativeFund.tsx" /* webpackChunkName: "component---src-templates-alternative-fund-tsx" */),
  "component---src-templates-insight-page-tsx": () => import("./../../../src/templates/InsightPage.tsx" /* webpackChunkName: "component---src-templates-insight-page-tsx" */),
  "component---src-templates-legal-documentation-page-tsx": () => import("./../../../src/templates/LegalDocumentationPage.tsx" /* webpackChunkName: "component---src-templates-legal-documentation-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-pages-about-us-tsx": () => import("./../../../src/templates/pages/about-us.tsx" /* webpackChunkName: "component---src-templates-pages-about-us-tsx" */),
  "component---src-templates-pages-contacts-page-tsx": () => import("./../../../src/templates/pages/contacts-page.tsx" /* webpackChunkName: "component---src-templates-pages-contacts-page-tsx" */),
  "component---src-templates-pages-golden-visa-european-citizenship-tsx": () => import("./../../../src/templates/pages/golden-visa-european-citizenship.tsx" /* webpackChunkName: "component---src-templates-pages-golden-visa-european-citizenship-tsx" */),
  "component---src-templates-pages-index-tsx": () => import("./../../../src/templates/pages/index.tsx" /* webpackChunkName: "component---src-templates-pages-index-tsx" */),
  "component---src-templates-pages-insights-page-tsx": () => import("./../../../src/templates/pages/insights-page.tsx" /* webpackChunkName: "component---src-templates-pages-insights-page-tsx" */),
  "component---src-templates-pages-institutional-tsx": () => import("./../../../src/templates/pages/institutional.tsx" /* webpackChunkName: "component---src-templates-pages-institutional-tsx" */),
  "component---src-templates-pages-our-funds-tsx": () => import("./../../../src/templates/pages/our-funds.tsx" /* webpackChunkName: "component---src-templates-pages-our-funds-tsx" */),
  "component---src-templates-pages-what-we-do-tsx": () => import("./../../../src/templates/pages/what-we-do.tsx" /* webpackChunkName: "component---src-templates-pages-what-we-do-tsx" */),
  "component---src-templates-security-fund-tsx": () => import("./../../../src/templates/SecurityFund.tsx" /* webpackChunkName: "component---src-templates-security-fund-tsx" */)
}

