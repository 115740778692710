import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { t } from 'i18next';
import { FaWhatsapp } from 'react-icons/fa';
import ButtonLink, { ButtonLinkProps } from './ButtonLink';
import * as styles from './WhatsappButton.module.scss';

export interface WhatsappButtonProps extends Omit<ButtonLinkProps, 'children'> {
  className?: string;
}

interface QueryData {
  sanityWebsite: {
    contacts: {
      whatsAppNumber?: string;
    };
  };
}

const WhatsappButton = ({ className, ...restProps }: WhatsappButtonProps): React.ReactElement => {
  const data: QueryData = useStaticQuery(graphql`
    {
      sanityWebsite {
        contacts {
          whatsAppNumber
        }
      }
    }
  `);

  return (
    <ButtonLink
      className={className}
      to={'https://wa.me/' + data.sanityWebsite.contacts.whatsAppNumber?.replace(/[^\d]/g, '')}
      withWhatsappColor
      {...restProps}
    >
      <FaWhatsapp className={styles.icon} />
      <span>{t('footer.whatsapp_banner', 'Fale connosco')}</span>
    </ButtonLink>
  );
};

export default WhatsappButton;
