import '../styles/main.scss';

import { graphql } from 'gatsby';
import React, { useRef } from 'react';

import groq from 'groq';
import { useTranslation } from 'react-i18next';
import ModulesContent, { Module } from '../components/ModulesContent';
import PageSEO from '../components/PageSEO';
import Hero from '../components/ui/Hero';
import SanityLayout from '../components/ui/SanityLayout';
import { ALL_LANGS } from '../constants';
import { ButtonLinkType } from '../graphql-fragments/ButtonLink';
import { SanityImageType } from '../graphql-fragments/SanityImage';
import { LocalizedSEOWebsite } from '../graphql-fragments/seo';
import { withPagePreview } from '../preview/previewUtils';
import { withI18n } from '../utils/hocs';
import { useDimensions, useScrollY } from '../utils/hooks';
import { localize } from '../utils/sanity';

export const query = graphql`
  query GetPageById($id: String!) {
    sanityPage(_id: { eq: $id }) {
      title
      slug {
        current
      }
      hero {
        ...Hero
      }
      modules {
        ...Modules
      }
      seo {
        ...SEOWebsite
      }
    }
    sanityWebsite {
      contacts {
        calendlyLink
      }
    }
  }
`;

const groqQuery = groq`{
  "sanityPage": *[_id == $id][0] {
     ...
  }
}`;

export type BackgroundColorTheme = '#E5F8E8' | '#EFECFA';

interface LocalizedQueryData {
  sanityPage: {
    title: string;
    slug: {
      current: string;
    };
    hero: {
      title: string;
      subtitle?: string;
      text?: string;
      videoUrl: string;
      videoTranscription?: string;
      videoTitle?: string;
      videoSubtitle?: string;
      brochure?: {
        asset: {
          url: string;
        };
      };
      brochureUrl?: string;
      mobileBackgroundImage: SanityImageType;
      desktopBackgroundImage: SanityImageType;
      addWhatsappButton?: boolean;
    };
    modules: Array<Module>;
    seo: LocalizedSEOWebsite;
  };
  sanityWebsite: {
    contacts: {
      calendlyLink: string;
    };
  };
}

const Page = withPagePreview<LocalizedQueryData>(
  {
    groqQuery,
  },
  ({ data }): React.ReactElement => {
    const { i18n, t } = useTranslation();

    const langs = [i18n.language, ...ALL_LANGS.filter(lang => lang !== i18n.language)];
    const localizedData: LocalizedQueryData = localize(data, langs);

    const { hero, modules, seo } = localizedData.sanityPage;

    const heroRef = useRef<HTMLElement | null>(null);
    const { height: heroHeight } = useDimensions(heroRef, { measureOnScroll: false }) || {};
    const scrollY = useScrollY();
    const headerWithOpaqueBackground = !!scrollY && !!heroHeight && scrollY > heroHeight;

    const headerButton: ButtonLinkType = {
      text: t('header.schedule_meeting', 'Agendar reunião'),
      linkType: 'externalLink',
      externalLink: { url: undefined, linkToCalendly: true },
    };

    return (
      <SanityLayout
        headerCtaButton={headerButton}
        headerWithOpaqueBackground={headerWithOpaqueBackground}
        marginlessFooter
      >
        <PageSEO pageSEO={seo} />
        <Hero
          heroType="heroWithImage"
          mobileImage={hero.mobileBackgroundImage}
          desktopImage={hero.desktopBackgroundImage}
          title={hero.title}
          subtitle={hero.subtitle}
          text={hero.text}
          buttonLink={headerButton}
          videoUrl={hero.videoUrl}
          videoTitle={hero.videoTitle}
          videoSubtitle={hero.videoSubtitle}
          videoTranscription={hero.videoTranscription}
          brochureUrl={hero.brochureUrl || hero.brochure?.asset.url}
          imageType="background"
          sideOptionslayout="textLeft"
          heroRef={heroRef}
          calendlyLink={data.sanityWebsite.contacts.calendlyLink}
          addWhatsappButton={data.sanityPage.hero.addWhatsappButton}
        />
        <ModulesContent modules={modules} calendlyLink={data.sanityWebsite.contacts.calendlyLink} />
      </SanityLayout>
    );
  },
);

export default withI18n(Page);
