import { graphql } from 'gatsby';
import React from 'react';
import { BackgroundColorTheme } from '../templates/Page';
import CTAModule, { CTAModuleProps } from './ui/modules/CTAModule';
import ImageWithBranchingInfoModule, {
  ImageWithBranchingInfoModuleProps,
} from './ui/modules/ImageWithBranchingInfoModule';
import ItemGridModule, { ItemGridModuleProps } from './ui/modules/ItemGridModule';
import TextAndImageModule, { TextAndImageModuleProps } from './ui/modules/TextAndImageModule';
import TextWithBackgroundImageModule, {
  TextWithBackgroundImageModuleProps,
} from './ui/modules/TextWithBackgroundImageModule';
import VideoModule, { VideoModuleProps } from './ui/modules/VideoModule';

export const ModulesFragment = graphql`
  fragment Modules on SanityPageCtaModuleOrPageImageWithBranchingInfoModuleOrPageItemGridModuleOrPageTextAndImageModuleOrPageTextWithBackgroundImageModuleOrPageVideoModule {
    __typename
    ...TextAndImageModule
    ... on SanityPageTextAndImageModule {
      moduleId
      disabled
    }
    ...ItemGridModule
    ... on SanityPageItemGridModule {
      moduleId
      disabled
    }
    ...TextWithBackgroundImageModule
    ... on SanityPageTextWithBackgroundImageModule {
      moduleId
      disabled
    }
    ...ImageWithBranchingInfoModule
    ... on SanityPageImageWithBranchingInfoModule {
      moduleId
      disabled
    }
    ...CTAModule
    ... on SanityPageCtaModule {
      moduleId
      disabled
    }
    ...VideoModule
    ... on SanityPageVideoModule {
      moduleId
      disabled
    }
  }
`;

export interface ModulesContentProps {
  modules: Array<Module>;
  calendlyLink: string;
}

export interface CommonModuleProps {
  moduleId?: string;
  disabled?: boolean | null;
  previousModuleBgColor?: BackgroundColorTheme;
}

export type Module =
  | (TextAndImageModuleProps & CommonModuleProps & { __typename: 'SanityPageTextAndImageModule' })
  | (TextWithBackgroundImageModuleProps &
      CommonModuleProps & { __typename: 'SanityPageTextWithBackgroundImageModule' })
  | (ItemGridModuleProps & CommonModuleProps & { __typename: 'SanityPageItemGridModule' })
  | (ImageWithBranchingInfoModuleProps &
      CommonModuleProps & { __typename: 'SanityPageImageWithBranchingInfoModule' })
  | (CTAModuleProps & CommonModuleProps & { __typename: 'SanityPageCtaModule' })
  | (VideoModuleProps & CommonModuleProps & { __typename: 'SanityPageVideoModule' });

function ModulesContent({ modules, calendlyLink }: ModulesContentProps): React.ReactElement {
  const enabledModules = modules.filter(module => !module.disabled);

  return (
    <>
      {enabledModules.map((module, moduleIndex) => {
        const moduleKey = 'module-' + moduleIndex;

        if (module.__typename === 'SanityPageTextAndImageModule') {
          return <TextAndImageModule key={moduleKey} {...module}></TextAndImageModule>;
        }
        if (module.__typename === 'SanityPageItemGridModule') {
          return <ItemGridModule key={moduleKey} {...module}></ItemGridModule>;
        }
        if (module.__typename === 'SanityPageTextWithBackgroundImageModule') {
          return (
            <TextWithBackgroundImageModule
              key={moduleKey}
              {...module}
            ></TextWithBackgroundImageModule>
          );
        }
        if (module.__typename === 'SanityPageImageWithBranchingInfoModule') {
          return (
            <ImageWithBranchingInfoModule
              key={moduleKey}
              {...module}
            ></ImageWithBranchingInfoModule>
          );
        }
        if (module.__typename === 'SanityPageCtaModule') {
          return <CTAModule key={moduleKey} {...module} calendlyLink={calendlyLink}></CTAModule>;
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (module.__typename === 'SanityPageVideoModule') {
          return <VideoModule key={moduleKey} {...module}></VideoModule>;
        }

        // @ts-expect-error
        throw new Error('Got unexpected typename: ' + module.__typename);
      })}
    </>
  );
}

export default ModulesContent;
