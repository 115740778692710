/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import BlockContent from '@sanity/block-content-to-react';
import React from 'react';

import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import serializers from '../../serializers';
import { BackgroundColorTheme } from '../../templates/Page';
import { RawPortableText } from '../../types/types';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import { clsx, replaceNewLinesWithBr, slugify } from '../../utils/utils';
import ButtonLink from './ButtonLink';
import * as styles from './ModuleLayout.module.scss';

export interface ModuleLayoutProps {
  id?: string;
  title?: string;
  text?: string | RawPortableText;
  buttons?: Array<ButtonLinkType>;
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  textContainerClassName?: string;
  contentDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse' | 'centered';
  previousModuleBgColor?: BackgroundColorTheme;
  currentModuleBgColor?: BackgroundColorTheme;
  childrenWithoutHorizontalPadding?: boolean;
  childrenClassName?: string;
  headingVariant?: 'h1' | 'h2';
  moduleRef?: React.MutableRefObject<HTMLElement | null>;
}

export function getModuleId(id?: string, title?: string) {
  return id || (title && slugify(title));
}

const ModuleLayout = ({
  id,
  title,
  text,
  buttons,
  children,
  className,
  contentClassName,
  textContainerClassName,
  contentDirection,
  currentModuleBgColor,
  childrenWithoutHorizontalPadding,
  childrenClassName,
  headingVariant,
  moduleRef,
}: ModuleLayoutProps): React.ReactElement => {
  return (
    <section
      ref={moduleRef}
      className={clsx(
        className,
        styles.moduleContainer,
        childrenWithoutHorizontalPadding &&
          contentDirection !== 'row' &&
          contentDirection !== 'row-reverse' &&
          styles.childrenWithoutHorizontalPadding,
      )}
      id={getModuleId(id, title)}
      data-theme={''}
    >
      <div
        className={clsx(
          styles.contentContainer,
          contentDirection === 'row' && styles.rowLayout,
          contentDirection === 'row-reverse' && styles.rowReverseLayout,
          contentDirection === 'column-reverse' && styles.columnReverseLayout,
          contentDirection === 'centered' && styles.centeredLayout,
          contentClassName,
        )}
      >
        {(title || text || (buttons && buttons.length > 0)) && (
          <div className={clsx(textContainerClassName, styles.textWrapper)}>
            <div className={styles.textContainer}>
              {title &&
                (headingVariant === 'h1' ? (
                  <h1 className={styles.title}>{title}</h1>
                ) : (
                  <h2 className={styles.title}>{title}</h2>
                ))}
              {text &&
                (typeof text === 'string' ? (
                  <p className={styles.text}>{replaceNewLinesWithBr(text)}</p>
                ) : (
                  <BlockContent
                    renderContainerOnSingleChild
                    blocks={text}
                    serializers={serializers}
                    className={styles.text}
                  />
                ))}
              {buttons && buttons.length > 0 && (
                <div className={styles.buttonsContainer}>
                  {buttons.map((button, index) => (
                    <ButtonLink
                      key={index}
                      to={getUrlFromVersatileLink(button)}
                      outlined={index - 1 === buttons.length}
                    >
                      {button.text}
                    </ButtonLink>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {children && contentDirection !== 'centered' && (
          <div className={clsx(childrenClassName, styles.children)}>{children}</div>
        )}
      </div>
    </section>
  );
};

export default ModuleLayout;
