// extracted by mini-css-extract-plugin
export var centerAligned = "ListItem-module--centerAligned--1d004";
export var horizontalLayout = "ListItem-module--horizontalLayout--87537";
export var itemBullet = "ListItem-module--itemBullet--fc579";
export var itemBulletContainer = "ListItem-module--itemBulletContainer--3e85c";
export var itemContainer = "ListItem-module--itemContainer--28d1f";
export var itemImageContainer = "ListItem-module--itemImageContainer--2ed4c";
export var itemNumber = "ListItem-module--itemNumber--e519e";
export var itemTitle = "ListItem-module--itemTitle--4dead";
export var lessGapBetweenElements = "ListItem-module--lessGapBetweenElements--01b51";
export var link = "ListItem-module--link--116e3";
export var textContainer = "ListItem-module--textContainer--99520";
export var withImage = "ListItem-module--withImage--fbadb";
export var withLargerImage = "ListItem-module--withLargerImage--efa6b";
export var wrapper = "ListItem-module--wrapper--9cae9";