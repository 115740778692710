import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import groq from 'groq';
import { useTranslation } from 'react-i18next';
import { ALL_LANGS, SHOW_ALTERNATIVE_FUNDS, SHOW_MUTUAL_FUNDS } from '../../constants';
import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import { SingleEntry } from '../../graphql-fragments/navigationMenu';
import logo from '../../images/heedCapitalLogo.svg';
import logoWhite from '../../images/heedCapitalLogo_white.svg';
import PreviewLoadingScreen from '../../preview/PreviewLoadingScreen';
import { usePreviewData } from '../../preview/previewUtils';
import { localize } from '../../utils/sanity';
import { SocialNetworksBlock } from './Footer';
import Layout from './Layout';

interface SanityLayoutProps {
  children: React.ReactNode;
  headerCtaButton?: ButtonLinkType;
  headerWithOpaqueBackground?: boolean;
  withFormAnchorLink?: boolean;
  marginlessFooter?: boolean;
  withOldDesign?: boolean;
}

interface QueryData {
  sanityWebsite: {
    topBanner?: {
      text: string;
      link: {
        slug: {
          current: string;
        };
      };
    };
    contacts: {
      address: string;
      email: string;
      phoneNumbers: Array<string>;
      whatsAppNumber?: string;
      calendlyLink: string;
    };
    socialMedia: {
      facebookUrl: string;
      linkedInUrl: string;
      twitterUrl: string;
      instagramUrl: string;
    };
  };
  allSanityLegalDocumentationPage: {
    totalCount: number;
    nodes: Array<{
      title: string;
      slug: {
        current: string;
      };
    }>;
  };
}

const SanityLayout = ({
  children,
  headerCtaButton,
  headerWithOpaqueBackground,
  withFormAnchorLink,
  marginlessFooter,
  withOldDesign,
}: SanityLayoutProps): React.ReactElement => {
  const staticData = useStaticQuery<QueryData>(graphql`
    {
      sanityWebsite {
        topBanner {
          text {
            ...LocaleStringFragment
          }
          link {
            slug {
              current
            }
          }
        }
        contacts {
          address {
            ...LocaleTextFragment
          }
          email
          phoneNumbers
          whatsAppNumber
          calendlyLink
        }
        socialMedia {
          facebookUrl
          linkedInUrl
          twitterUrl
          instagramUrl
        }
      }
      allSanityLegalDocumentationPage {
        totalCount
        nodes {
          title {
            ...LocaleStringFragment
          }
          slug {
            current
          }
        }
      }
    }
  `);
  const groqQuery = groq`{
    "sanityWebsite": *[_type == "website"][0] {
      ...
    }
  }`;

  const data = usePreviewData<QueryData>(staticData, {
    groqQuery,
  });

  if (!data) {
    return <PreviewLoadingScreen></PreviewLoadingScreen>;
  }

  const { i18n, t } = useTranslation();

  const langs = [i18n.language, ...ALL_LANGS.filter(lang => lang !== i18n.language)];
  const localizedData: QueryData = localize(data, langs);

  const socialNetwork: SocialNetworksBlock = {
    title: t('footer.follow_social_media', 'Siga-nos'),
    socialNetworks: [
      { iconName: 'facebook', url: localizedData.sanityWebsite.socialMedia.facebookUrl },
      { iconName: 'linkedin', url: localizedData.sanityWebsite.socialMedia.linkedInUrl },
      { iconName: 'twitter', url: localizedData.sanityWebsite.socialMedia.twitterUrl },
      { iconName: 'instagram', url: localizedData.sanityWebsite.socialMedia.instagramUrl },
    ],
  };

  const navigationMenu = [
    {
      linkType: 'externalLink',
      externalLink: {
        url: '/about-us',
      },
      title: t('menu.aboutUs', 'Quem Somos'),
    },
    {
      linkType: 'externalLink',
      externalLink: {
        url: '/what-we-do',
      },
      title: t('menu.whatWeDo', 'O Que Fazemos'),
    },
    ...(SHOW_MUTUAL_FUNDS || SHOW_ALTERNATIVE_FUNDS
      ? [
          {
            linkType: 'externalLink',
            externalLink: {
              url: '/our-funds',
            },
            title: t('menu.ourFunds', 'Os Nossos Fundos'),
          },
        ]
      : []),
    {
      linkType: 'externalLink',
      externalLink: {
        url: '/golden-visa',
      },
      title: t('menu.goldenVisa', 'Visto Gold'),
    },
    {
      linkType: 'externalLink',
      externalLink: {
        url: '/institutional',
      },
      title: t('menu.institutional', 'Institucional'),
    },
    {
      linkType: 'externalLink',
      externalLink: {
        url: '/contacts-page',
      },
      title: t('menu.contactsPage', 'Contactos'),
      hideInDesktop: true,
    },
  ] as Array<SingleEntry>;

  const legalDocsEntry = {
    title: t('footer.legal_documentation', 'Páginas legais'),
    docs: localizedData.allSanityLegalDocumentationPage.nodes,
  };

  return (
    <Layout
      topBanner={localizedData.sanityWebsite.topBanner}
      withOldDesign={withOldDesign}
      headerConfig={{
        sticky: true,
        logoImage: logo,
        loogImageWhite: logoWhite,
        entries: navigationMenu,
        ctaButton: headerCtaButton,
        withOpaqueBackground: headerWithOpaqueBackground,
        calendlyLink: localizedData.sanityWebsite.contacts.calendlyLink,
        withFormAnchorLink: withFormAnchorLink,
      }}
      footerConfig={{
        logoImage: logo,
        socialNetworksBlock: socialNetwork,
        legalDocsBlock:
          localizedData.allSanityLegalDocumentationPage.nodes.length > 0
            ? legalDocsEntry
            : undefined,
        contacts: {
          title: t('footer.contacts', 'Contactos'),
          address: localizedData.sanityWebsite.contacts.address,
          email: localizedData.sanityWebsite.contacts.email,
          phoneNumbers: localizedData.sanityWebsite.contacts.phoneNumbers,
          whatsAppNumber: localizedData.sanityWebsite.contacts.whatsAppNumber,
        },
        marginlessFooter: marginlessFooter,
      }}
    >
      {children}
    </Layout>
  );
};

export default SanityLayout;
