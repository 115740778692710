import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import React, { useContext, useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';

import { LocationContext } from '../../contexts/LocationContext';
import { clsx, getCookieValue, setCookie } from '../../utils/utils';
import * as styles from './CookiesBanner.module.scss';

const COOKIE_NAME = 'CookieConsent';

interface CookiesBannerProps {
  textContent: React.ReactNode;
}

const CookiesBanner = ({ textContent }: CookiesBannerProps): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const location = useContext(LocationContext)!;
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (getCookieValue('CookieConsent') === undefined) {
      setHidden(false);
    }
  }, []);

  return (
    <div className={clsx(styles.container, hidden && styles.hidden)}>
      <div className={styles.innerContainer}>
        <div className={styles.content}>{textContent}</div>
        <div className={styles.buttonsWrapper}>
          <button
            className={styles.button + ' ' + styles.declineButton}
            onClick={() => {
              setCookie(COOKIE_NAME, 'false', { expires: 3650, sameSite: 'strict' });
              setHidden(true);
            }}
          >
            Decline
          </button>
          <button
            className={styles.button}
            onClick={() => {
              setCookie(COOKIE_NAME, 'true', { expires: 3650, sameSite: 'strict' });
              setHidden(true);
              initializeAndTrack(location);
            }}
          >
            Accept
          </button>
        </div>
      </div>
      <button
        className={styles.closeButton}
        onClick={() => {
          setHidden(true);
        }}
      >
        <CgClose />
        <div className={styles.visuallyHidden}>Close Cookies Banner</div>
      </button>
    </div>
  );
};

export default CookiesBanner;
