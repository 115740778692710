export const BREAKPOINTS = {
  phone: 360,
  phablet: 520,
  tabletVertical: 720,
  tabletHorizontal: 960,
  smallLaptop: 1200,
  laptop: 1400,
  desktop: 1800,
  largeDesktop: 2400,
} as const;
