import React, { Component } from 'react';

interface OnLoadEvent {
  path?: Array<{ width: number; height: number }>;
  target?: { width: number; height: number };
}

interface ImageWithFallbacksProps {
  src: string;
  fallbackImage?: string | Array<string>;
  alt: string;
  initialImage?: string;
  initialTimeout?: number;
  onLoad?: (src: string) => void;
  onError?: (src: string) => void;
  getConsiderSuccessAsError: (e: OnLoadEvent) => boolean;
  className?: string;
  onClick?: () => void;
}

interface ImageWithFallbacksState {
  imageSource: string | null;
}

/**
 * Displays an image and fallbacks to a default image or set of fallback images.
 * In case the main image fails to load. It also allows for conditional error handling and initial image timeout.
 * Useful when we don't know whether the image url we have exists and we want to provide a few fallbacks.
 *
 * @param src - The image source URL
 * @param fallbackImage - Fallback image source URL or an array of fallbacks
 * @param alt - The alt text for the image
 * @param initialImage - Initial image to show before main image loaded
 * @param initialTimeout - Time in milliseconds before showing initial image before main image loads
 * @param onLoad - Function called once image has loaded, passed the loaded image source path as string argument
 * @param onError - Function called if image fails to load, passed the failed image source path as string argument
 * @param getConsiderSuccessAsError - Optional function that returns a boolean; if true, the success event listener will treat it as an error and move to the next fallback image
 * @param className - Class name(s) for the component
 * @param onClick - Function called on click
 */
export default class ImageWithFallbacks extends Component<
  ImageWithFallbacksProps,
  ImageWithFallbacksState
> {
  isLoaded: boolean;
  displayImage: HTMLImageElement | null = null;

  constructor(props: ImageWithFallbacksProps) {
    super(props);
    this.state = {
      imageSource: null,
    };
    this.setDisplayImage = this.setDisplayImage.bind(this);
    this.handleInitialTimeout = this.handleInitialTimeout.bind(this);
    this.isLoaded = false;
  }

  handleInitialTimeout(): void {
    if (this.props.initialTimeout && this.props.initialTimeout > 0) {
      setTimeout(() => {
        if (!this.isLoaded) {
          this.setState({
            imageSource: this.props.initialImage || null,
          });
        }
      }, this.props.initialTimeout);
    } else {
      this.setState({
        imageSource: this.props.initialImage || null,
      });
    }
  }

  componentDidMount(): void {
    this.handleInitialTimeout();
    this.displayImage = new window.Image();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.setDisplayImage({ image: this.props.src, fallbacks: this.props.fallbackImage! });
  }

  componentDidUpdate(nextProps: ImageWithFallbacksProps): void {
    if (nextProps.src !== this.props.src) {
      this.isLoaded = false;
      if (nextProps.initialImage) {
        this.handleInitialTimeout();
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.setDisplayImage({ image: nextProps.src, fallbacks: nextProps.fallbackImage! });
    }
  }

  componentWillUnmount(): void {
    if (this.displayImage) {
      this.displayImage.onerror = null;
      this.displayImage.onload = null;
      this.displayImage = null;
    }
  }

  handleError(imagesArray: Array<string>): boolean {
    if (imagesArray.length >= 2 && typeof imagesArray[1] === 'string') {
      const updatedFallbacks = imagesArray.slice(2);
      this.setDisplayImage({ image: imagesArray[1], fallbacks: updatedFallbacks });
      return true;
    }
    return false;
  }

  setDisplayImage({
    image,
    fallbacks,
  }: {
    image: string;
    fallbacks: string | Array<string>;
  }): void {
    const imagesArray = ([image] as Array<string>).concat(fallbacks).filter(fallback => !!fallback);
    if (this.displayImage !== null) {
      this.displayImage.onerror = () => {
        if (this.handleError(imagesArray)) {
          return;
        }

        this.isLoaded = true;
        this.setState(
          {
            imageSource: imagesArray[1] || null,
          },
          () => {
            if (this.props.onError) {
              this.props.onError(this.props.src);
            }
          },
        );
      };
      this.displayImage.onload = e => {
        if (
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          this.props.getConsiderSuccessAsError &&
          this.props.getConsiderSuccessAsError(e as unknown as OnLoadEvent) &&
          this.handleError(imagesArray)
        ) {
          return;
        }
        this.isLoaded = true;
        this.setState(
          {
            imageSource: imagesArray[0],
          },
          () => {
            if (this.props.onLoad) {
              this.props.onLoad(imagesArray[0]);
            }
          },
        );
      };
      if (typeof imagesArray[0] === 'string') {
        this.displayImage.src = imagesArray[0];
      } else {
        this.setState(
          {
            imageSource: imagesArray[0],
          },
          () => {
            if (this.props.onLoad) {
              this.props.onLoad(imagesArray[0]);
            }
          },
        );
      }
    }
  }

  render(): React.ReactElement | null {
    return typeof this.state.imageSource === 'string' ? (
      <img
        className={this.props.className}
        onClick={this.props.onClick}
        src={this.state.imageSource}
        alt={this.props.alt}
      />
    ) : (
      this.state.imageSource
    );
  }
}
