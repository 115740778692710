import React from 'react';
import PreviewErrorScreen from './PreviewErrorScreen';
import PreviewLoadingScreen from './PreviewLoadingScreen';

export class PreviewErrorBoundary extends React.Component<
  { children: React.ReactNode; onReset?: () => void; data?: any },
  { hasError: boolean; loading: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, loading: false };
  }

  componentDidUpdate(prevProps): void {
    if (this.props.data !== prevProps.data) {
      this.setState({
        hasError: false,
      });
      if (this.props.onReset) {
        this.props.onReset();
      }
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true, loading: false };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.loading) {
      return <PreviewLoadingScreen></PreviewLoadingScreen>;
    }

    if (this.state.hasError) {
      return (
        <PreviewErrorScreen
          title="Preview crashed due to invalid data"
          message={
            <>
              <strong>Fix all validation errors in the backoffice</strong> and the preview should
              recover,
              <br />
              otherwise try using the button below or refreshing the page.
            </>
          }
          onTryAgain={() => {
            if (this.props.onReset) {
              this.props.onReset();
            }
            this.setState({
              hasError: false,
              loading: true,
            });
            setTimeout(() => {
              this.setState({
                hasError: false,
                loading: false,
              });
            }, 500);
          }}
        ></PreviewErrorScreen>
      );
    }

    return this.props.children;
  }
}
