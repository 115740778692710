import BlockContent from '@sanity/block-content-to-react';
import React from 'react';

import Image from './components/ui/Image';
import ListItem from './components/ui/ListItem';
import LocalizedLink from './components/ui/LocalizedLink';
import TextLink from './components/ui/TextLink';
import Video from './components/ui/Video';
import { SanityImageType } from './graphql-fragments/SanityImage';
import * as styles from './serializers.module.scss';
import { PageDocumentType, RawPortableText } from './types/types';
import { getReferenceUrl } from './utils/projectUtils';
import { getPageDocumentUrl } from './utils/sanity';
import { clsx, slugify } from './utils/utils';

export const InternalLinkSerializer = (props: {
  mark: {
    pageReference: {
      slug: { current: string };
      _type: PageDocumentType;
    };
    anchorLink?: string;
  };
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <TextLink to={getPageDocumentUrl(props.mark.pageReference, props.mark.anchorLink)}>
      {props.children}
    </TextLink>
  );
};

const ExternalLinkSerializer = (props: {
  mark: {
    url: string;
  };
  children: React.ReactNode;
}): React.ReactElement => {
  return <TextLink to={props.mark.url}>{props.children}</TextLink>;
};

const BlockSerializer = (props: {
  node: {
    children: Array<{
      text: string;
    }>;
    style?: string;
  };
  children: React.ReactNode;
}): React.ReactElement => {
  const slug = slugify(props.node.children.map(child => child.text).join(''));
  if (props.node.style === 'h2') {
    return <h2 id={slug}>{props.children}</h2>;
  }
  if (props.node.style === 'h3') {
    return <h3 id={slug}>{props.children}</h3>;
  }
  if (props.node.style === 'h4') {
    return <h4 id={slug}>{props.children}</h4>;
  }
  if (props.node.style === 'h5') {
    return <h5 id={slug}>{props.children}</h5>;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return BlockContent.defaultSerializers.types.block(props);
};

const ListSerializer = (props: {
  type: string;
  level: number;
  children: React.ReactNode;
}): React.ReactElement => {
  if (props.type === 'number' && props.level === 2) {
    return <ol type="a">{props.children}</ol>;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
  return BlockContent.defaultSerializers.list(props);
};

const ListItemsSerializer = (props: {
  node: {
    items: Array<{
      title: string;
      text: string;
    }>;
  };
}): React.ReactElement => {
  return (
    <ul>
      {props.node.items.map((item, index) => (
        <ListItem
          listType="withBullet"
          text={item.text}
          horizontalLayout
          title={item.title}
          key={index}
          className={styles.listItem}
        />
      ))}
    </ul>
  );
};

const CustomListSerializer = (props: {
  node: {
    listItems: Array<{
      title?: string;
      text: RawPortableText;
    }>;
    listType: 'simple' | 'numbered' | 'grid';
  };
}): React.ReactElement => {
  const ListElType = props.node.listType === 'numbered' ? 'ol' : 'ul';
  return (
    <ListElType
      className={clsx(
        styles.list,
        props.node.listType === 'numbered' && styles.numberedList,
        props.node.listType === 'grid' && styles.gridList,
        props.node.listType === 'simple' && styles.simpleList,
      )}
    >
      {props.node.listItems.map((item, index) => (
        <li key={index} className={styles.listItem}>
          {props.node.listType === 'grid' && <div className={styles.line}></div>}
          {props.node.listType === 'numbered' && (
            <div className={styles.numberAndLine}>
              <div className={styles.numberWrapper}>
                <span className={styles.numberContainer}>{index + 1}</span>
              </div>
              <div className={styles.line}></div>
            </div>
          )}
          <div className={styles.textContainer}>
            {item.title && <h3 className={styles.itemTitle}>{item.title}</h3>}
            <BlockContent
              blocks={item.text}
              renderContainerOnSingleChild
              serializers={serializers}
              className={styles.itemText}
            />
          </div>
        </li>
      ))}
    </ListElType>
  );
};

const VideoSerializer = (props: {
  node: { url: string; isAVerticalVideo?: boolean };
}): React.ReactElement => {
  return <Video url={props.node.url} isAVerticalVideo={props.node.isAVerticalVideo} />;
};

const LogoImageSerializer = (props: {
  node: { logo: SanityImageType; title?: string };
}): React.ReactElement => {
  return (
    <div className={styles.logoImageContainer}>
      {props.node.title && <span className={styles.logoTitle}>{props.node.title}</span>}
      <div className={styles.logoContainer}>
        <Image image={props.node.logo} className={styles.logo} />
      </div>
    </div>
  );
};

const GenericLinkSerializer = (props: {
  mark: {
    url: string;
  };
  children: React.ReactNode;
}): React.ReactElement => {
  return <a href={props.mark.url}>{props.children}</a>;
};

const EmbeddedImageSerializer = (props: {
  node: {
    image: {
      asset: {
        originalFilename: string;
        url: string;
      };
    };
    imageWidth: number;
  };
}): React.ReactElement => {
  return (
    <div className={styles.embbededImageWrapper}>
      <img
        src={props.node.image.asset.url + '?w=' + (props.node.imageWidth || '680') + '&fit=max'}
        alt={props.node.image.asset.originalFilename}
      />
    </div>
  );
};

const InsightLinkSerializer = (props: {
  mark: {
    insight: {
      slug: { current: string };
    };
  };
  children: React.ReactNode;
}): React.ReactElement => {
  return (
    <LocalizedLink to={getReferenceUrl('SanityInsight', props.mark.insight.slug.current)}>
      {props.children}
    </LocalizedLink>
  );
};

const YoutubeSerializer = (props: { node: { url: string } }): React.ReactElement => {
  return (
    <div className={styles.video}>
      <Video url={props.node.url}></Video>
    </div>
  );
};

const FundLinkSerializer = (props: {
  mark: {
    fund: {
      slug: { current: string };
      internal: { type: 'SanityAlternativeFund' | 'SanitySecurityFund' };
    };
  };
  children: React.ReactNode;
}): React.ReactElement => {
  console.log(props);
  return props.mark.fund.internal?.type === 'SanityAlternativeFund' ? (
    <LocalizedLink to={getReferenceUrl('SanityAlternativeFund', props.mark.fund.slug.current)}>
      {props.children}
    </LocalizedLink>
  ) : (
    <LocalizedLink to={getReferenceUrl('SanitySecurityFund', props.mark.fund.slug.current)}>
      {props.children}
    </LocalizedLink>
  );
};

const serializers = {
  marks: {
    internalLink: InternalLinkSerializer,
    externalLink: ExternalLinkSerializer,
    genericLink: GenericLinkSerializer,
    fundLink: FundLinkSerializer,
    insightLink: InsightLinkSerializer,
  },
  types: {
    video: VideoSerializer,
    block: BlockSerializer,
    listItems: ListItemsSerializer,
    customList: CustomListSerializer,
    logoImage: LogoImageSerializer,
    youtube: YoutubeSerializer,
    embeddedImage: EmbeddedImageSerializer,
  },
  list: ListSerializer,
};

export default serializers;
