import React, { useContext } from 'react';

import { LocationContext } from '../../contexts/LocationContext';
import { SingleEntry } from '../../graphql-fragments/navigationMenu';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import { checkUrlsMatch, clsx } from '../../utils/utils';
import * as styles from './MenuEntry.module.scss';
import SmartLink from './SmartLink';
import { getLocalizedUrlPath } from '../../utils/i18n';
import { useTranslation } from 'react-i18next';

export interface MenuEntryProps {
  entry: SingleEntry;
  withMorePaddingLeft?: boolean;
  headerWithOpaqueBackground?: boolean;
  hideInDesktop?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const MenuEntry = ({
  entry,
  withMorePaddingLeft,
  headerWithOpaqueBackground,
  hideInDesktop,
  className,
  onClick,
}: MenuEntryProps): React.ReactElement => {
  const location = useContext(LocationContext);
  const { i18n } = useTranslation();
  return (
    <SmartLink
      onClick={onClick}
      to={getUrlFromVersatileLink(entry)}
      className={clsx(
        styles.link,
        location &&
          checkUrlsMatch(
            location.pathname,
            getLocalizedUrlPath(getUrlFromVersatileLink(entry), i18n.language),
          ) &&
          styles.active,
        withMorePaddingLeft && styles.morePaddingLeft,
        headerWithOpaqueBackground && styles.headerWithOpaqueBackground,
        hideInDesktop && styles.hideInDesktop,
        className,
      )}
    >
      {entry.title}
    </SmartLink>
  );
};

export default MenuEntry;
