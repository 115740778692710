import { useGSAP } from '@gsap/react';
import BlockContent from '@sanity/block-content-to-react';
import { graphql } from 'gatsby';
import gsap from 'gsap';
import React, { useRef } from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import serializers from '../../../serializers';
import * as serializersStyles from '../../../serializers.module.scss';
import { BREAKPOINTS } from '../../../styles/breakpoints';
import { BackgroundColorTheme } from '../../../templates/Page';
import { RawPortableText } from '../../../types/types';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps } from '../../ModulesContent';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './TextWithBackgroundImageModule.module.scss';

export const TextWithBackgroundImageModuleFragment = graphql`
  fragment TextWithBackgroundImageModule on SanityPageTextWithBackgroundImageModule {
    title {
      ...LocaleStringFragment
    }
    text {
      _type
      _rawPt(resolveReferences: { maxDepth: 4 })
      _rawEn(resolveReferences: { maxDepth: 4 })
    }
    mobileImage {
      ...SanityImage
    }
    desktopImage {
      ...SanityImage
    }
    textBackgroundColor
  }
`;

export type TextWithBackgroundImageModuleProps = {
  title?: string;
  text: RawPortableText;
  mobileImage: SanityImageType;
  desktopImage: SanityImageType;
  textBackgroundColor: BackgroundColorTheme;
  className?: string;
};

function TextWithBackgroundImageModule(
  props: TextWithBackgroundImageModuleProps & CommonModuleProps,
): React.ReactElement {
  const { title, text, textBackgroundColor, mobileImage, desktopImage, className, moduleId } =
    props;
  const containerRef = useRef<HTMLElement | null>(null);
  useGSAP(
    () => {
      gsap.from(`.${styles.title}, .${serializersStyles.listItem}, .${styles.text}`, {
        x: -100,
        opacity: 0,
        duration: 1,
        ease: 'circ.out',
        stagger: { each: 0.2 },

        scrollTrigger: {
          trigger: '.' + styles.textContainer,
          start: 'top 75%',
        } satisfies ScrollTrigger.Vars,
      });

      // image parallax
      gsap.fromTo(
        '.' + styles.imageContainer,
        {
          y: '-20%',
          transform: 'scale(1.2)',
          ease: 'none',
        },
        {
          y: '20%',
          scrollTrigger: {
            start: 'top bottom',
            end: 'bottom top',
            trigger: '.' + styles.content,
            scrub: true,
          } satisfies ScrollTrigger.Vars,
        },
      );
    },
    { scope: containerRef },
  );

  return (
    <ModuleLayout
      id={moduleId}
      moduleRef={containerRef}
      className={clsx(className, styles.container)}
      contentClassName={clsx(styles.contentContainer)}
      childrenClassName={styles.content}
      childrenWithoutHorizontalPadding
    >
      <div className={styles.imageContainer}>
        <Image
          className={clsx(styles.image)}
          image={mobileImage}
          dimensions={[
            [1000, 1800],
            [BREAKPOINTS.tabletHorizontal, desktopImage, 2880, 1920],
          ]}
          cover
        />
      </div>
      <div
        className={clsx(
          styles.textContainer,
          textBackgroundColor === '#E5F8E8' ? styles.greenBackground : styles.purpleBackground,
        )}
      >
        <h2 className={clsx(styles.title)}>{title}</h2>
        <BlockContent
          blocks={text}
          className={styles.text}
          renderContainerOnSingleChild
          serializers={serializers}
        />
      </div>
    </ModuleLayout>
  );
}

export default TextWithBackgroundImageModule;
