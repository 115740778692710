// extracted by mini-css-extract-plugin
export var branchContainer = "ImageWithBranchingInfoModule-module--branchContainer--1bc2e";
export var branchLineHorizontal = "ImageWithBranchingInfoModule-module--branchLineHorizontal--3992e";
export var branchLineVertical = "ImageWithBranchingInfoModule-module--branchLineVertical--37f3a";
export var contentContainer = "ImageWithBranchingInfoModule-module--contentContainer--70376";
export var contentWrapper = "ImageWithBranchingInfoModule-module--contentWrapper--f7c22";
export var desktop = "ImageWithBranchingInfoModule-module--desktop--559dc";
export var dot = "ImageWithBranchingInfoModule-module--dot--bd1a8";
export var dotContainer = "ImageWithBranchingInfoModule-module--dotContainer--63068";
export var imageAndInfoContainer = "ImageWithBranchingInfoModule-module--imageAndInfoContainer--f96f2";
export var imageContainer = "ImageWithBranchingInfoModule-module--imageContainer--ec2da";
export var imageWrapper = "ImageWithBranchingInfoModule-module--imageWrapper--3f97a";
export var info = "ImageWithBranchingInfoModule-module--info--118ac";
export var infoContainer = "ImageWithBranchingInfoModule-module--infoContainer--537df";
export var infosContainer = "ImageWithBranchingInfoModule-module--infosContainer--5d1e0";
export var leftBranch = "ImageWithBranchingInfoModule-module--leftBranch--763f2";
export var lineContainer = "ImageWithBranchingInfoModule-module--lineContainer--58f15";
export var mobile = "ImageWithBranchingInfoModule-module--mobile--1aeb8";
export var titleContainer = "ImageWithBranchingInfoModule-module--titleContainer--93bc6";
export var verticalContainer = "ImageWithBranchingInfoModule-module--verticalContainer--063ad";