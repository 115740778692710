import React from 'react';
import Helmet from 'react-helmet';

interface SEOProps {
  title: string;
  shareTitle?: string;
  description: string;
  shareDescription?: string;
  url: string;
  canonicalUrl?: string;
  imageUrl?: string;
  lang: string;
  author: string;
  noIndex?: boolean;
  noFollow?: boolean;
}

function SEO({
  title,
  shareTitle,
  description,
  shareDescription,
  url,
  canonicalUrl,
  imageUrl,
  lang,
  author,
  noIndex,
  noFollow,
}: SEOProps): React.ReactElement {
  return (
    <Helmet>
      <html lang={lang} />
      <title>{title}</title>
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      <link type="text/plain" rel="author" href="/humans.txt" />
      <meta
        name="robots"
        content={(noIndex ? 'noindex' : 'index') + ', ' + (noFollow ? 'nofollow' : 'follow')}
      ></meta>
      <meta property="og:type" content="website"></meta>
      <meta name="twitter:card" content="summary_large_image"></meta>
      {(shareTitle || title) && <meta property="og:title" content={shareTitle || title}></meta>}
      {(shareTitle || title) && <meta name="twitter:title" content={shareTitle || title}></meta>}
      {description && <meta name="description" content={description}></meta>}
      {(shareDescription || description) && (
        <meta property="og:description" content={shareDescription || description}></meta>
      )}
      {(shareDescription || description) && (
        <meta name="twitter:description" content={shareDescription || description}></meta>
      )}
      {url && <meta property="og:url" content={url}></meta>}
      {imageUrl && <meta property="og:image" content={imageUrl}></meta>}
      {imageUrl && <meta property="og:image:width" content="1200"></meta>}
      {imageUrl && <meta property="og:image:height" content="630"></meta>}
      {imageUrl && <meta name="twitter:image" content={imageUrl}></meta>}
      <meta name="author" content={author}></meta>
      <meta name="twitter:creator" content={author}></meta>
    </Helmet>
  );
}

export default SEO;
