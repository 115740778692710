import React from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from './ListPagination.module.scss';

interface ListPaginationProps {
  currentPage: number;
  totalItems: number;
  itemsPerPage: number;
  onPrevPageClick: () => void;
  onNextPageClick: () => void;
  onPageClick: (pageNumber: number) => void;
}

const ListPagination = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPrevPageClick,
  onNextPageClick,
  onPageClick,
}: ListPaginationProps): React.ReactElement => {
  const { t } = useTranslation();

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  function renderPaginationNumbers() {
    const result: Array<React.ReactElement> = [];
    let lastNumberRendered: number | null = null;
    const firstPage = 1;
    for (let pageNumber = firstPage; pageNumber <= totalPages; pageNumber++) {
      const requiredChecks =
        pageNumber === currentPage ||
        pageNumber === currentPage - 1 ||
        pageNumber === currentPage + 1 ||
        // pageNumber === firstPage ||
        // pageNumber === totalPages ||
        (firstPage < pageNumber && pageNumber < currentPage && currentPage - firstPage <= 2) ||
        (currentPage < pageNumber && pageNumber < totalPages && totalPages - currentPage <= 2) ||
        (firstPage <= currentPage && currentPage < pageNumber && pageNumber - firstPage <= 2) ||
        (pageNumber < currentPage && currentPage <= totalPages && totalPages - pageNumber <= 2);
      const allChecks =
        requiredChecks ||
        pageNumber === firstPage ||
        pageNumber === totalPages ||
        (firstPage < pageNumber && pageNumber < currentPage && currentPage - firstPage <= 3) ||
        (currentPage < pageNumber && pageNumber < totalPages && totalPages - currentPage <= 3) ||
        (firstPage <= currentPage && currentPage < pageNumber && pageNumber - firstPage <= 4) ||
        (pageNumber < currentPage && currentPage <= totalPages && totalPages - pageNumber <= 4);
      if (allChecks) {
        if (lastNumberRendered !== null && lastNumberRendered !== pageNumber - 1) {
          result.push(
            <div key={pageNumber + '...'} className={styles.pageNumber + ' ' + styles.ellipsis}>
              ...
            </div>,
          );
        }
        result.push(
          <div
            key={pageNumber}
            className={
              styles.pageNumber +
              (currentPage === pageNumber ? ' ' + styles.selected : '') +
              (!requiredChecks ? ' ' + styles.optional : '')
            }
            onClick={() => {
              onPageClick(pageNumber);
            }}
          >
            {pageNumber}
          </div>,
        );
        lastNumberRendered = pageNumber;
      }
    }
    return result;
  }

  return (
    <div className={styles.container}>
      <span
        className={styles.button + (currentPage <= 1 ? ' ' + styles.disabled : '')}
        onClick={onPrevPageClick}
      >
        <span className={styles.label + ' titleParagraph'}>
          {t('list_pagination.previous', 'Previous')}
        </span>
      </span>
      <div className={styles.numbers + ' titleParagraph'}>{renderPaginationNumbers()}</div>
      <span
        className={styles.button + (currentPage >= totalPages ? ' ' + styles.disabled : '')}
        onClick={onNextPageClick}
      >
        <span className={styles.label + ' titleParagraph'}>
          {t('list_pagination.next', 'Next')}
        </span>
      </span>
    </div>
  );
};

export default ListPagination;
