import React from 'react';
import { NestedEntry, SingleEntry, isNestedEntry } from '../../graphql-fragments/navigationMenu';
import { clsx } from '../../utils/utils';

import * as styles from './Menu.module.scss';
import MenuEntry from './MenuEntry';
import NestedMenuEntry from './NestedMenuEntry';

interface MenuProps {
  entries: Array<SingleEntry | NestedEntry>;
  headerWithOpaqueBackground?: boolean;
  className?: string;
}

const Menu = ({
  entries,
  headerWithOpaqueBackground,
  className,
}: MenuProps): React.ReactElement => {
  return (
    <nav className={clsx(styles.menuDesktop, className)} aria-label="Navigation">
      <div className={styles.container}>
        {entries.map((entry, i: number) =>
          isNestedEntry(entry) ? (
            <NestedMenuEntry entry={entry} key={i} />
          ) : (
            <MenuEntry
              entry={entry}
              key={i}
              headerWithOpaqueBackground={headerWithOpaqueBackground}
              hideInDesktop={entry.hideInDesktop}
            />
          ),
        )}
      </div>
    </nav>
  );
};

export default Menu;
