import { useGSAP } from '@gsap/react';
import BlockContent from '@sanity/block-content-to-react';
import gsap from 'gsap';

import { graphql } from 'gatsby';
import React, { useRef } from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import serializers from '../../../serializers';
import { BREAKPOINTS } from '../../../styles/breakpoints';
import { RawPortableText } from '../../../types/types';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps } from '../../ModulesContent';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './ImageWithBranchingInfoModule.module.scss';

export const ImageWithBranchingInfoModuleFragment = graphql`
  fragment ImageWithBranchingInfoModule on SanityPageImageWithBranchingInfoModule {
    title {
      ...LocaleStringFragment
    }
    text {
      ...LocaleTextFragment
    }
    image {
      ...SanityImage
    }
    infos {
      text {
        ...LocaleBoldPortableText
      }
    }
  }
`;

export type ImageWithBranchingInfoModuleProps = {
  title: string;
  text?: string;
  image: SanityImageType;
  infos: Array<{ text: RawPortableText }>;
  className?: string;
};

function ImageWithBranchingInfoModule(
  props: ImageWithBranchingInfoModuleProps & CommonModuleProps,
): React.ReactElement {
  const { image, title, text, infos, className, moduleId } = props;

  const evenIndexArrayInfos = infos.filter((_, index) => index % 2 === 0);
  const oddIndexInfos = infos.filter((_, index) => index % 2 !== 0);

  function renderInfosContainer(
    infos: Array<{ text: RawPortableText }>,
    isInTheLeftSide?: boolean,
  ) {
    return infos.map((info, i) => (
      <div className={clsx(styles.infoContainer, isInTheLeftSide && styles.leftBranch)} key={i}>
        <div className={styles.branchContainer}>
          <div className={styles.verticalContainer}>
            <div className={styles.dotContainer}>
              <div className={styles.dot}></div>
            </div>
            <div className={styles.lineContainer}>
              <div className={styles.branchLineVertical}></div>
              <div className={clsx(styles.branchLineHorizontal)}></div>
            </div>
          </div>
        </div>
        <BlockContent
          blocks={info.text}
          renderContainerOnSingleChild
          serializers={serializers}
          className={styles.info}
        />
      </div>
    ));
  }

  const containerRef = useRef<HTMLElement | null>(null);
  useGSAP(
    () => {
      const desktopLeftInfoContainers = gsap.utils.toArray<HTMLDivElement>(
        `.${styles.desktop} .${styles.infoContainer}.${styles.leftBranch}`,
      );

      const desktopRightInfoContainers = gsap.utils.toArray<HTMLDivElement>(
        `.${styles.desktop} .${styles.infoContainer}:not(.${styles.leftBranch})`,
      );

      const desktopInfoContainers: Array<HTMLDivElement> = [];
      for (let i = 0; i < desktopLeftInfoContainers.length; i++) {
        desktopInfoContainers.push(desktopLeftInfoContainers[i]);
        if (i < desktopRightInfoContainers.length) {
          desktopInfoContainers.push(desktopRightInfoContainers[i]);
        }
      }

      const mobileInfoContainers = gsap.utils.toArray<HTMLDivElement>(
        `.${styles.mobile} .${styles.infoContainer}`,
      );

      [desktopInfoContainers, mobileInfoContainers].forEach(infoContainers => {
        infoContainers.forEach((target, i) => {
          const isLeftSide = target.classList.contains(styles.leftBranch);

          const tl = gsap.timeline({
            delay: isLeftSide ? 0 : 0.5,
            scrollTrigger: {
              trigger: target,
              start: 'top 75%',
            } satisfies ScrollTrigger.Vars,
          });

          // entrance
          tl.from(target, {
            opacity: 0,
            y: -20,
            duration: 1,
          });

          gsap.context(() => {
            tl
              // animate vertical line
              .fromTo(
                '.' + styles.branchLineVertical,
                {
                  height: 0,
                },
                {
                  duration: 0.5,
                  height: '100%',
                },
              )
              // animate horizontal line
              .fromTo(
                `.${styles.branchLineHorizontal}`,
                {
                  width: 0,
                  ease: 'circ.out',
                  duration: 0.5,
                },
                {
                  width: isLeftSide ? 500 : 100,
                },
              );
          }, target);
        });
      });
    },
    { scope: containerRef },
  );

  return (
    <ModuleLayout
      id={moduleId}
      moduleRef={containerRef}
      className={clsx(className, styles.container)}
      contentClassName={styles.contentContainer}
      childrenClassName={styles.contentWrapper}
      childrenWithoutHorizontalPadding
    >
      <div className={styles.titleContainer}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {text && <p className={styles.text}>{text}</p>}
      </div>
      <div className={styles.imageAndInfoContainer}>
        <div className={clsx(styles.infosContainer, styles.desktop)}>
          {renderInfosContainer(evenIndexArrayInfos, true)}
        </div>
        <div className={styles.imageWrapper}>
          <div className={styles.imageContainer}>
            <Image
              className={styles.image}
              image={image}
              dimensions={[
                [600, 2800],
                [BREAKPOINTS.phablet, 800, 2000],
              ]}
            />
          </div>
        </div>
        <div className={clsx(styles.infosContainer, styles.mobile)}>
          {renderInfosContainer(infos)}
        </div>
        <div className={clsx(styles.infosContainer, styles.desktop)}>
          {renderInfosContainer(oddIndexInfos)}
        </div>
      </div>
    </ModuleLayout>
  );
}

export default ImageWithBranchingInfoModule;
