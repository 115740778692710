// extracted by mini-css-extract-plugin
export var backgroundImage = "VideoModule-module--backgroundImage--acf2c";
export var backgroundImageContainer = "VideoModule-module--backgroundImageContainer--c10c5";
export var container = "VideoModule-module--container--e2bf3";
export var contentContainer = "VideoModule-module--contentContainer--f361e";
export var contentWrapper = "VideoModule-module--contentWrapper--14bfa";
export var link = "VideoModule-module--link--20177";
export var modalTitleContainer = "VideoModule-module--modalTitleContainer--3984e";
export var modalVideoSubtitle = "VideoModule-module--modalVideoSubtitle--b983c";
export var modalVideoTitle = "VideoModule-module--modalVideoTitle--a786f";
export var overlay = "VideoModule-module--overlay--88ba2";
export var text = "VideoModule-module--text--13a75";
export var textContainer = "VideoModule-module--textContainer--64616";
export var title = "VideoModule-module--title--37951";
export var video = "VideoModule-module--video--259e0";
export var videoContainer = "VideoModule-module--videoContainer--4c9d1";
export var videoTranscriptionContainer = "VideoModule-module--videoTranscriptionContainer--f004c";
export var videoTranscriptionSupertitle = "VideoModule-module--videoTranscriptionSupertitle--f892e";