declare global {
  interface Window {
    Calendly?: {
      initPopupWidget(options: { url: string }): void;
      showPopupWidget(url: string): void;
    };
  }
}

export function handleCalendlyClick(link: string) {
  if (window.Calendly) {
    window.Calendly.initPopupWidget({ url: link });
  }
}

export function getMenuLinkClasses(
  currentPathname: string,
  linkUrl: string,
  selectedClass: string,
): string {
  let classes = '';

  let currentPathnameCleaned = currentPathname.trim();
  let linkUrlCleaned = linkUrl.trim();
  if (currentPathnameCleaned.endsWith('/')) {
    currentPathnameCleaned = currentPathnameCleaned.substr(0, currentPathnameCleaned.length - 1);
  }

  if (linkUrlCleaned.endsWith('/')) {
    linkUrlCleaned = linkUrlCleaned.substr(0, linkUrlCleaned.length - 1);
  }
  if (currentPathnameCleaned === linkUrlCleaned) {
    classes += ' ' + selectedClass;
  }
  return classes;
}

export function formatBytes(bytes: number, decimalPlaces = 2): string {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const log1024 = Math.floor(Math.log(bytes) / Math.log(1024));
  const unit = ['b', 'K', 'M', 'G', 'T', 'P'][log1024];
  if (!unit) {
    throw new Error('Could not find a unit for given bytes number: ' + bytes);
  }
  // Using parseFloat to remove trailing zeros
  return parseFloat((bytes / Math.pow(1024, log1024)).toFixed(decimalPlaces)) + unit;
}

export function getReferenceUrl(
  typeName: 'SanityInsight' | 'SanityAlternativeFund' | 'SanitySecurityFund',
  slug: string,
): string {
  switch (typeName) {
    case 'SanityInsight':
      return '/insight/' + slug;
    case 'SanityAlternativeFund':
      return '/alternative-fund/' + slug;
    case 'SanitySecurityFund':
      return '/mutual-fund/' + slug;
    default:
      throw new Error('typeName value not known: ' + typeName);
  }
}
