// extracted by mini-css-extract-plugin
export var buttonsContainer = "TextAndImageModule-module--buttonsContainer--90fc6";
export var companyLogo = "TextAndImageModule-module--companyLogo--b6d2e";
export var container = "TextAndImageModule-module--container--07d22";
export var contentContainer = "TextAndImageModule-module--contentContainer--aa087";
export var contentWrapper = "TextAndImageModule-module--contentWrapper--467ff";
export var darkBackground = "TextAndImageModule-module--darkBackground--9585b";
export var greenBackground = "TextAndImageModule-module--greenBackground--3fd19";
export var image = "TextAndImageModule-module--image--e5709";
export var imageContainer = "TextAndImageModule-module--imageContainer--867d4";
export var imageOnRight = "TextAndImageModule-module--imageOnRight--5a792";
export var landscapeImage = "TextAndImageModule-module--landscapeImage--9ff36";
export var mediaContainer = "TextAndImageModule-module--mediaContainer--41498";
export var neutralBackground = "TextAndImageModule-module--neutralBackground--0a650";
export var purpleBackground = "TextAndImageModule-module--purpleBackground--c7ff2";
export var text = "TextAndImageModule-module--text--f731e";
export var textContainer = "TextAndImageModule-module--textContainer--83a6a";
export var title = "TextAndImageModule-module--title--de114";