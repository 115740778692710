/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import { NestedEntry, SingleEntry, isNestedEntry } from '../../graphql-fragments/navigationMenu';
import { capitalize, sum } from '../../utils/nodash';
import { getUrlFromVersatileLink } from '../../utils/sanity';

import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ALL_LANGS, AUTHOR_NAME, DEFAULT_LANG } from '../../constants';
import { LocationContext } from '../../contexts/LocationContext';
import { BackgroundColorTheme } from '../../templates/Page';
import { getLocalizedUrlPath } from '../../utils/i18n';
import { handleCalendlyClick } from '../../utils/projectUtils';
import { checkIsInternalUrl, clsx } from '../../utils/utils';
import ButtonLink from './ButtonLink';
import DropDownMenu from './DropDownMenu';
import * as styles from './Header.module.scss';
import LocalizedLink from './LocalizedLink';
import Menu from './Menu';
import MenuButton from './MenuButton';
import { pushCalendlyButtonClickEvent, withDataLayer } from '../../tracking/dataLayer';

export interface HeaderProps {
  sticky?: boolean;
  logoImage: string;
  loogImageWhite: string;
  entries: Array<SingleEntry | NestedEntry>;
  ctaButton?: ButtonLinkType;
  withOpaqueBackground?: boolean;
  calendlyLink: string;
  theme?: BackgroundColorTheme;
  withFormAnchorLink?: boolean;
}

const Header = ({
  sticky,
  logoImage,
  loogImageWhite,
  entries,
  ctaButton,
  theme,
  calendlyLink,
  withOpaqueBackground,
  withFormAnchorLink,
}: HeaderProps): React.ReactElement => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // NOTE: this could probably be replaced by calculating the menu size
  // automatically with javascript.
  // TODO Check these sizes on each project
  const MENU_GAP_SIZE = 24; // spacing-medium
  const MENU_ENTRY_PADDING_SIZE = 8; // spacing-x-small
  const MENU_CHAR_SIZE = 10;
  const MENU_EXTERNAL_URL_ICON_SIZE = 20;
  // Small menus are shown from tablet vertical upwards
  const MEDIUM_MENU_MIN_SIZE = 500; // Fits only from table horizontal upwards
  const LARGE_MENU_MIN_SIZE = 900; // Fits only from laptop upwards
  const HUGE_MENU_MIN_SIZE = 1200; // Doesn't fit anywhere

  // Calculates the menu size so we can inject corresponding classes
  // to hide the menu when it doesn't fit anymore
  const menuApproximateSize =
    entries.filter(
      entry => !isNestedEntry(entry) && !checkIsInternalUrl(getUrlFromVersatileLink(entry)),
    ).length *
      MENU_EXTERNAL_URL_ICON_SIZE +
    sum(entries.map(({ title }) => title.length * MENU_CHAR_SIZE + MENU_ENTRY_PADDING_SIZE * 2)) +
    (entries.length - 1) * MENU_GAP_SIZE;
  const menuSizeCategory =
    menuApproximateSize >= HUGE_MENU_MIN_SIZE
      ? 'huge'
      : menuApproximateSize >= LARGE_MENU_MIN_SIZE
      ? 'large'
      : menuApproximateSize >= MEDIUM_MENU_MIN_SIZE
      ? 'medium'
      : 'small';

  const { i18n, t } = useTranslation();

  const location = useContext(LocationContext);

  let pathWithoutCountry = location ? location.pathname : '';
  if (
    ALL_LANGS.filter(lang => lang !== DEFAULT_LANG).filter(lang =>
      pathWithoutCountry.match(new RegExp(`^/${lang}($|/)`)),
    ).length > 0
  ) {
    pathWithoutCountry = pathWithoutCountry.substr(3) || '/';
  }

  const originalDocumentOverflow = useRef<null | string>(null);

  useEffect(() => {
    if (isMenuOpen) {
      originalDocumentOverflow.current = document.documentElement.style.overflow || '';
      document.documentElement.style.overflow = 'hidden';
    } else {
      if (originalDocumentOverflow.current !== null) {
        document.documentElement.style.overflow = originalDocumentOverflow.current || '';
        originalDocumentOverflow.current = null;
      }
    }
  }, [isMenuOpen]);

  return (
    <>
      {sticky && (
        <Helmet>
          <html className={styles.html}></html>
        </Helmet>
      )}
      <header
        className={clsx(
          styles.header,
          styles['headerWithMenu' + capitalize(menuSizeCategory)],
          sticky && styles.stickyHeader,
          withOpaqueBackground && styles.withOpaqueBackground,
        )}
        data-theme={''}
      >
        <div className={styles.headerContainer}>
          <div className={styles.multilingueContainer}>
            <Link
              to={getLocalizedUrlPath(pathWithoutCountry, 'pt')}
              className={clsx(styles.languageLink, i18n.language === 'pt' && styles.active)}
            >
              PT
            </Link>
            <span>|</span>
            <Link
              to={getLocalizedUrlPath(pathWithoutCountry, 'en')}
              className={clsx(styles.languageLink, i18n.language === 'en' && styles.active)}
            >
              EN
            </Link>
          </div>

          <div className={styles.logoWrapper}>
            <div className={styles.logoContainer}>
              <LocalizedLink to="/" className={styles.logoLink}>
                <img src={withOpaqueBackground ? logoImage : loogImageWhite} alt="" />
                <div className={styles.visuallyHidden}>{AUTHOR_NAME}</div>
              </LocalizedLink>
            </div>
          </div>

          <Menu
            entries={entries}
            className={styles.desktopMenu}
            headerWithOpaqueBackground={withOpaqueBackground}
          ></Menu>

          <div className={styles.linksContainer}>
            {!ctaButton && (
              <div className={styles.ctaButton}>
                {withFormAnchorLink ? (
                  <ButtonLink to={'#form-anchor'}>
                    {t('menuContactButton.title', 'Contacte-nos')}
                  </ButtonLink>
                ) : (
                  <ButtonLink to="/contacts-page">
                    {t('menuContactButton.title', 'Contacte-nos')}
                  </ButtonLink>
                )}
              </div>
            )}
            {ctaButton && (
              <div className={styles.ctaButton}>
                <ButtonLink
                  to={getUrlFromVersatileLink(ctaButton)}
                  onClick={() => {
                    if (ctaButton.externalLink?.linkToCalendly) {
                      handleCalendlyClick(calendlyLink);
                      withDataLayer(pushCalendlyButtonClickEvent({ location: 'header' }));
                    }
                  }}
                  withCalendarIcon={!!ctaButton.externalLink?.linkToCalendly}
                >
                  {ctaButton.text}
                </ButtonLink>
              </div>
            )}

            <MenuButton
              className={styles.menuButton}
              isMenuOpen={isMenuOpen}
              onTouch={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
              headerWithOpaqueBackground={withOpaqueBackground}
            ></MenuButton>
          </div>
        </div>

        <div className={styles.dropDownMenuWrapperWindow}>
          <div
            className={clsx(
              styles.dropDownMenuWrapper,
              isMenuOpen ? styles.menuOpen : styles.menuClosed,
            )}
          >
            <DropDownMenu
              headerWithOpaqueBackground={withOpaqueBackground}
              entries={entries}
              ctaButton={ctaButton}
              onEntryClick={() => {
                setIsMenuOpen(false);
              }}
              onCtaButtonClick={() => {
                setIsMenuOpen(false);
                handleCalendlyClick(calendlyLink);
              }}
            ></DropDownMenu>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
