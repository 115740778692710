/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useGSAP } from '@gsap/react';
import { graphql } from 'gatsby';
import gsap from 'gsap';
import React, { useRef } from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps } from '../../ModulesContent';
import ListItem, { ListItemProps } from '../ListItem';
import ModuleLayout from '../ModuleLayout';
import * as styles from './ItemGridModule.module.scss';

export const ItemGridModuleFragment = graphql`
  fragment ItemGridModule on SanityPageItemGridModule {
    title {
      ...LocaleStringFragment
    }
    items {
      title {
        ...LocaleStringFragment
      }
      text {
        ...LocaleTextFragment
      }
      icon {
        ...SanityImage
      }
    }
    withSmallIcons
  }
`;

interface ItemGrid {
  title: string;
  text: string;
  icon?: SanityImageType;
}

export type ItemGridModuleProps = {
  title?: string;
  items: Array<ItemGrid>;
  withSmallIcons?: boolean;
  className?: string;
};

function ItemGridModule(props: ItemGridModuleProps & CommonModuleProps): React.ReactElement {
  const { title, items, withSmallIcons, className, moduleId } = props;
  const containerRef = useRef<HTMLElement | null>(null);
  useGSAP(
    () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '.' + styles.itemsContainer,
          start: 'top bottom',
        } satisfies ScrollTrigger.Vars,
      });

      tl
        // animate each element
        .from('.' + styles.itemContainer, {
          opacity: 0,
          y: 100,
          duration: 1,
          ease: 'power2.inOut',
          stagger: {
            each: Math.max(1.2 / items.length, 0.2),
          },
        })
        // animate grid lines (before & after)
        .from(
          '.' + styles.itemContainer,
          {
            '--lineOpacity': '0',
            ease: 'power2.inOut',
          },
          '-=0.2',
        )
        .from(
          '.' + styles.divider,
          {
            opacity: 0,
            ease: 'power2.inOut',
          },
          '-=0.2',
        );
    },
    { scope: containerRef },
  );

  return (
    <ModuleLayout id={moduleId} className={className} title={title} moduleRef={containerRef}>
      <ul
        className={clsx(
          styles.itemsContainer,
          !items.find(item => item.icon) && styles.withNoIcon,
          items.find(item => item.icon) && !withSmallIcons && styles.withLargeIcons,
          items.find(item => item.icon) && withSmallIcons && styles.withSmallIcons,
          (items.length === 3 || items.length === 5 || items.length === 6 || items.length === 12) &&
            styles.threeInRow,
          (items.length === 4 || (items.length >= 7 && items.length !== 12)) && styles.fourInRow,
        )}
      >
        {items.map((item, i) => {
          const listType = item.icon ? 'withImage' : 'withoutMarkers';

          const listItemProps = {
            listType,
            text: item.text,
            title: item.title,
            ...(listType === 'withImage' && item.icon
              ? { imageSize: withSmallIcons ? 'small' : 'large', image: item.icon }
              : {}),
          } as ListItemProps;

          return (
            <React.Fragment key={i}>
              <ListItem {...listItemProps} className={clsx(styles.itemContainer)} />
              <li className={styles.divider}></li>
            </React.Fragment>
          );
        })}
      </ul>
    </ModuleLayout>
  );
}

export default ItemGridModule;
