import React from 'react';
import { clsx } from '../../utils/utils';

import * as styles from './MenuButton.module.scss';

interface MenuButtonProps {
  isMenuOpen: boolean;
  headerWithOpaqueBackground?: boolean;
  onTouch: () => void;
  className?: string;
}

const MenuButton = ({
  isMenuOpen,
  headerWithOpaqueBackground,
  onTouch,
  className,
}: MenuButtonProps): React.ReactElement => {
  return (
    <button
      className={clsx(
        className,
        styles.menuWrap,
        isMenuOpen && styles.menuOpen,
        headerWithOpaqueBackground && styles.headerWithOpaqueBackground,
      )}
      onClick={onTouch}
    >
      <div className={styles.icon}>
        <div className={styles.line}></div>
        <div className={styles.visuallyHidden}>Menu</div>
      </div>
    </button>
  );
};

export default MenuButton;
