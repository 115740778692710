import React from 'react';
import * as styles from './PreviewErrorScreen.module.scss';
import alertIcon from './assets/alert-circle.svg';

interface PreviewErrorScreenProps {
  title: string;
  message: React.ReactNode;
  onTryAgain?: () => void;
}

export default function PreviewErrorScreen({
  title,
  message,
  onTryAgain,
}: PreviewErrorScreenProps) {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <img src={alertIcon}></img>
        <div className={styles.textContainer}>
          <span className={styles.errorSpan}>
            <div className={styles.divider}></div> Preview error
          </span>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.message}>{message}</p>
          {onTryAgain && (
            <button
              className={styles.button}
              onClick={() => {
                if (onTryAgain) {
                  onTryAgain();
                }
              }}
            >
              Try again
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
