import React from 'react';
import { FaCircleExclamation } from 'react-icons/fa6';

type InputFieldPropsBase<InputElement> = {
  id: string;
  name: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  value: string | null;
  onBlur: () => void;
  error: boolean;
  helperText: string;
  containerClass?: string;
  containerErrorClass?: string;
  helperTextClass?: string;
  helperTextErrorClass?: string;
  helperTextWithDisplayNone?: boolean;
} & (
  | {
      onChange: (value: string) => void;
      dontUnpackValue?: false;
    }
  | {
      onChange: (value: React.ChangeEvent<InputElement>) => void;
      dontUnpackValue: true;
    }
);

type InputFieldPropsInput = InputFieldPropsBase<HTMLInputElement> & {
  type: string;
  textarea?: never;
};

type InputFieldPropsTextArea = InputFieldPropsBase<HTMLTextAreaElement> & {
  type?: never;
  textarea: true;
};

function InputField({
  id,
  name,
  type,
  placeholder,
  className,
  disabled,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  textarea,
  containerClass,
  containerErrorClass,
  helperTextClass,
  helperTextErrorClass,
  dontUnpackValue,
  helperTextWithDisplayNone,
  ...restProps
}: InputFieldPropsInput | InputFieldPropsTextArea): React.ReactElement {
  return (
    <div
      className={containerClass + (error && containerErrorClass ? ' ' + containerErrorClass : '')}
    >
      {textarea ? (
        <textarea
          id={id}
          className={className}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          value={value || ''}
          onChange={e => {
            if (dontUnpackValue) {
              onChange(e);
            } else {
              onChange(e.target.value);
            }
          }}
          onBlur={onBlur}
          {...restProps}
        />
      ) : (
        <input
          type={type}
          id={id}
          className={className}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          value={value || ''}
          onChange={e => {
            if (dontUnpackValue) {
              onChange(e);
            } else {
              onChange(e.target.value);
            }
          }}
          onBlur={onBlur}
          {...restProps}
        />
      )}
      <span
        className={
          helperTextClass + (error && helperTextErrorClass ? ' ' + helperTextErrorClass : '')
        }
        style={
          helperTextWithDisplayNone
            ? { display: !helperText ? 'none' : 'flex' }
            : { visibility: !helperText ? 'hidden' : 'visible' }
        }
      >
        <FaCircleExclamation />
        {helperText || 'x'}
      </span>
    </div>
  );
}

export default InputField;
