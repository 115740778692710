import { graphql } from 'gatsby';
import { VersatileLink } from '../types/types';

export const NavigationMenuFragment = graphql`
  fragment NavigationMenu on SanityMenuNestedEntryOrMenuSingleEntry {
    __typename
    ... on SanityMenuSingleEntry {
      title
      internalLink {
        pageReference {
          ...PageReference
        }
        anchorLink
      }
      externalLink {
        url
      }
    }
    ... on SanityMenuNestedEntry {
      title
      entries {
        title
        internalLink {
          pageReference {
            ...PageReference
          }
          anchorLink
        }
        externalLink {
          url
        }
      }
    }
  }
`;

export type SingleEntry = {
  title: string;
  hideInDesktop?: boolean;
} & VersatileLink;

export interface NestedEntry {
  title: string;
  entries: Array<SingleEntry>;
}

export type NavigationMenu = Array<SingleEntry | NestedEntry>;

export function isNestedEntry(entry: SingleEntry | NestedEntry): entry is NestedEntry {
  return 'entries' in entry;
}
