import React, { ReactNode } from 'react';
import { clsx } from '../../utils/utils';

import { FaCalendarAlt } from 'react-icons/fa';
import * as styles from './Button.module.scss';

export interface ButtonProps extends Omit<React.HTMLAttributes<HTMLElement>, 'children'> {
  children: string | ReactNode;
  outlined?: boolean;
  secondary?: boolean;
  withWhatsappColor?: boolean;
  withCalendarIcon?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  type?: 'submit';
  withOldDesign?: boolean;
  className?: string;
}

const Button = ({
  children,
  outlined,
  secondary,
  withWhatsappColor,
  withCalendarIcon,
  onClick,
  type,
  withOldDesign,
  className,
  ...restProps
}: ButtonProps): React.ReactElement => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        styles.button,
        className,
        secondary && styles.secondary,
        outlined && styles.outlined,
        withOldDesign && styles.oldDesign,
        withWhatsappColor && styles.withWhatsappColor,
      )}
      type={type === 'submit' ? 'submit' : 'button'}
      {...restProps}
    >
      {children}
      {withCalendarIcon && <FaCalendarAlt className={styles.calendarIcon} />}
    </button>
  );
};

export default Button;
