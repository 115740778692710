import React, { useContext, useRef, useState } from 'react';

import { ControlledMenu, MenuItem, useHover } from '@szhsin/react-menu';
import { FaChevronDown } from 'react-icons/fa';
import { LocationContext } from '../../contexts/LocationContext';
import { NestedEntry } from '../../graphql-fragments/navigationMenu';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import { checkUrlsMatch, clsx } from '../../utils/utils';
import MenuEntry from './MenuEntry';
import * as styles from './NestedMenuEntry.module.scss';

export interface NestedMenuEntryProps {
  entry: NestedEntry;
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
}

const NestedMenuEntry = ({
  entry,
  onClick,
  className,
}: NestedMenuEntryProps): React.ReactElement => {
  const location = useContext(LocationContext);

  const ref = useRef(null);
  const [isEntryOpen, setIsEntryOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isEntryOpen, setIsEntryOpen);
  const [bodyRef, setBodyRef] = useState<HTMLDivElement | null>(null);

  const bodyMaxHeight = isEntryOpen && bodyRef ? bodyRef.scrollHeight : 0;

  return (
    <div className={clsx(className, styles.container)}>
      {/* MOBILE */}
      <div className={styles.subMenuWrapperMobile}>
        <button
          onClick={() => {
            setIsEntryOpen(!isEntryOpen);
          }}
          className={clsx(
            styles.menuLink,
            isEntryOpen && styles.nestedEntryOpen,
            location &&
              entry.entries.some(subEntry =>
                checkUrlsMatch(location.pathname, getUrlFromVersatileLink(subEntry)),
              ) &&
              styles.active,
          )}
        >
          {entry.title}
          <FaChevronDown className={styles.icon} />
        </button>
        <div
          className={styles.nestedEntries}
          ref={newRef => {
            setBodyRef(newRef);
          }}
          style={{ maxHeight: bodyMaxHeight }}
        >
          {entry.entries.map((subEntry, i) => (
            <MenuEntry entry={subEntry} withMorePaddingLeft key={i} onClick={onClick} />
          ))}
        </div>
      </div>

      {/* DESKTOP */}
      <button
        className={clsx(
          styles.subMenuWrapperDesktop,
          isEntryOpen && styles.nestedEntryOpen,
          styles.menuLink,
          location &&
            entry.entries.some(subEntry =>
              checkUrlsMatch(location.pathname, getUrlFromVersatileLink(subEntry)),
            ) &&
            styles.active,
        )}
        ref={ref}
        aria-expanded={isEntryOpen}
        aria-disabled="false"
        aria-controls={'panel_menu'}
        tabIndex={0}
        role="button"
        {...anchorProps}
      >
        {entry.title}
        <FaChevronDown className={styles.icon} />
      </button>

      <ControlledMenu
        {...hoverProps}
        menuClassName={styles.subMenuDesktop}
        onClose={() => {
          setIsEntryOpen(false);
        }}
        state={isEntryOpen ? 'open' : 'closed'}
        anchorRef={ref}
        gap={8}
      >
        {entry.entries.map((subEntry, i) => (
          <MenuItem key={i} className={styles.nestedItem}>
            <MenuEntry entry={subEntry} key={i} onClick={onClick} />
          </MenuItem>
        ))}
      </ControlledMenu>
    </div>
  );
};

export default NestedMenuEntry;
