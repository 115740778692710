import React from 'react';

import { clsx } from '../../utils/utils';
import Button, { ButtonProps } from './Button';
import * as styles from './ButtonLink.module.scss';
import SmartLink from './SmartLink';

export interface ButtonLinkProps extends ButtonProps {
  to?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  buttonClassName?: string;
}

const ButtonLink = ({
  to,
  className,
  buttonClassName,
  ...restProps
}: ButtonLinkProps): React.ReactElement => {
  if (to && to !== '') {
    return (
      <SmartLink className={clsx(className, styles.link)} to={to} tabIndex={-1}>
        <Button {...restProps} className={buttonClassName} />
      </SmartLink>
    );
  }
  return <Button {...restProps} className={className}></Button>;
};

export default ButtonLink;
