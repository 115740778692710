import { pushFormSubmitEvent, withDataLayer } from './tracking/dataLayer';
import { TrackingData } from './utils/hooks';
import { urlJoin } from './utils/utils';

export async function sendNetlifyEmail(data: { [key: string]: string }) {
  if (process.env.NODE_ENV === 'development') {
    // Code to test form states (should be commented)
    // await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success
  }

  const resp = await fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: JSON.stringify(data),
  });
  if (resp.status !== 200) {
    throw new Error('Got response with status code ' + resp.status);
  }
  withDataLayer(pushFormSubmitEvent({ type: 'netlify-email' }));
  return true;
}

export async function sendContactToMailchimp({
  data,
  interests,
  trackingData,
}: {
  data: { [key: string]: string };
  interests: { [key: string]: string };
  trackingData: TrackingData;
}) {
  if (process.env.NODE_ENV === 'development') {
    // Code to test form states (should be commented)
    // await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success
  }

  const email = data.EMAIL;

  if (!email) {
    throw new Error(`Missing EMAIL in data: ${JSON.stringify(data)}`);
  }

  delete data.EMAIL;

  const body = {
    EMAIL: email,
    data: {
      ...data,
      U_SOURCE: trackingData.utmSource,
      U_MEDIUM: trackingData.utmMedium,
      U_CAMPAIGN: trackingData.utmCampaign,
      U_TERM: trackingData.utmTerm,
      U_CONTENT: trackingData.utmContent,
      U_DEVICE: trackingData.utmDevice,
      U_TARGET: trackingData.utmTarget,
      SRC_URL: urlJoin(window.location.origin, window.location.pathname),
      REFERRER: trackingData.websiteReferrer,
    },
    interests,
  };

  const resp = await fetch('/.netlify/functions/add-contact-to-mailchimp', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  if (resp.status !== 200) {
    throw new Error(
      `Got response with status code ${
        resp.status
      } for add-contact-to-mailchimp with data ${JSON.stringify(body)}`,
    );
  }

  withDataLayer(pushFormSubmitEvent({ type: 'mailchimp-add-contact' }));

  return true;
}
