import React from 'react';

import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import { NestedEntry, SingleEntry, isNestedEntry } from '../../graphql-fragments/navigationMenu';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import { clsx } from '../../utils/utils';
import ButtonLink from './ButtonLink';
import * as styles from './DropDownMenu.module.scss';
import MenuEntry from './MenuEntry';
import NestedMenuEntry from './NestedMenuEntry';
interface DropDownMenuProps {
  entries: Array<SingleEntry | NestedEntry>;
  className?: string;
  ctaButton?: ButtonLinkType;
  headerWithOpaqueBackground?: boolean;
  onEntryClick?: (entry: SingleEntry | NestedEntry, event: React.MouseEvent<HTMLElement>) => void;
  onCtaButtonClick?: React.MouseEventHandler<HTMLElement>;
}

const DropDownMenu = ({
  entries,
  ctaButton,
  className,
  headerWithOpaqueBackground,
  onEntryClick,
  onCtaButtonClick,
}: DropDownMenuProps): React.ReactElement => {
  return (
    <div
      className={clsx(
        styles.container,
        headerWithOpaqueBackground && styles.headerWithOpaqueBackground,
        className,
      )}
    >
      <div className={styles.contentContainer}>
        {entries.map((entry, i: number) => (
          <React.Fragment key={i}>
            {isNestedEntry(entry) ? (
              <NestedMenuEntry
                key={i}
                entry={entry}
                onClick={event => {
                  onEntryClick && onEntryClick(entry, event);
                }}
              />
            ) : (
              <MenuEntry
                entry={entry}
                headerWithOpaqueBackground={headerWithOpaqueBackground}
                key={i}
                onClick={event => {
                  onEntryClick && onEntryClick(entry, event);
                }}
              />
            )}
          </React.Fragment>
        ))}
      </div>

      {ctaButton && (
        <div className={styles.buttonContainer}>
          <ButtonLink
            to={getUrlFromVersatileLink(ctaButton)}
            className={styles.dropdownMenuButton}
            onClick={onCtaButtonClick}
            withCalendarIcon={!!ctaButton.externalLink?.linkToCalendly}
          >
            {ctaButton.text}
          </ButtonLink>
        </div>
      )}
    </div>
  );
};

export default DropDownMenu;
