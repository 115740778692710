/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import BlockContent from '@sanity/block-content-to-react';
import React, { useEffect, useRef, useState } from 'react';
import Select, { StylesConfig } from 'react-select';

import { graphql } from 'gatsby';
import { FaCircleExclamation } from 'react-icons/fa6';
import { sendContactToMailchimp, sendNetlifyEmail } from '../../api';
import submittedIcon from '../../images/formSubmittedIcon.svg';
import serializers from '../../serializers';
import { RawPortableText } from '../../types/types';
import { GenericField, useForm, useFormField } from '../../utils/forms';
import { useTrackingData } from '../../utils/hooks';
import { flatten, removeItem } from '../../utils/nodash';
import { assert, clsx, replaceNewLinesWithBr } from '../../utils/utils';
import * as styles from './Form.module.scss';
import InputField from './InputField';

export const FormFragment = graphql`
  fragment Form on SanityForm {
    title
    fields {
      fieldType
      displayType
      title
      _rawText(resolveReferences: { maxDepth: 4 })
      crmFieldId
      isGroupCategory
      withEmailValidation
      selectOptions {
        title
        crmValueStored
      }
      isFieldRequired
    }

    submitButtonText
    fileToDownloadAfterSubmit {
      asset {
        url
      }
    }
    thankYouScreen {
      title
      _rawText(resolveReferences: { maxDepth: 4 })
    }
  }
`;

type Option = {
  value: string;
  label: string;
};

/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any */
const customStyles: StylesConfig<Option> = {
  option: (defaultStyles, props) => ({
    ...defaultStyles,
    backgroundColor: props.isSelected
      ? 'var(--dropdown-background-color-selected)'
      : 'var(--dropdown-background-color)',
    cursor: props.isSelected ? 'default' : 'pointer',
    color: props.isSelected ? 'var(--color-white)' : 'var(--dropdown-text-color)',
    padding: 'var(--spacing-tiny) var(--spacing-small)',
    fontSize: 'var(--font-size-tiny)',
    fontFamily: 'var(--body-font-family)',
    margin: 0,
    outline: props.isFocused
      ? props.isSelected
        ? '1px solid var(--dropdown-background-color-hover)'
        : '1px solid var(--dropdown-background-color-selected)'
      : 'none',
    outlineOffset: '-1px',
    '&:first-child': {
      borderTopLeftRadius: 'var(--radius-tiny)',
      borderTopRightRadius: 'var(--radius-tiny)',
    },
    '&:last-child': {
      borderBottomLeftRadius: 'var(--radius-tiny)',
      borderBottomRightRadius: 'var(--radius-tiny)',
    },
    '&:hover': {
      backgroundColor: props.isSelected
        ? 'var(--dropdown-background-color-selected)'
        : 'var(--dropdown-background-color-hover)',
    },
  }),
  indicatorSeparator: defaultStyles => ({
    ...defaultStyles,
    display: 'none',
  }),
  indicatorsContainer: defaultStyles => ({
    ...defaultStyles,
    marginRight: 'var(--spacing-small)',
  }),
  dropdownIndicator: (defaultStyles, props) => ({
    ...defaultStyles,
    transition: 'all 0.5s ease-in-out',
    transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : undefined,
  }),
  singleValue: defaultStyles => ({
    ...defaultStyles,
    fontFamily: 'var(--body-font-family)',
    fontSize: 'var(--font-size-tiny)',
  }),
  placeholder: defaultStyles => ({
    ...defaultStyles,
    fontFamily: 'var(--body-font-family)',
    fontSize: 'var(--font-size-x-small)',
    color: 'var(--input-border-color)',
  }),
  control: (defaultStyles, props) => ({
    ...defaultStyles,
    backgroundColor: 'var(--input-background-color)',
    boxShadow: '0 0 0 0 transparent',
    height: 40,
    borderRadius: 'var(--radius-tiny)',
    border: '1px solid',
    cursor: 'pointer',
    borderColor: props.isFocused
      ? 'var(--input-border-color-focus)'
      : 'var(--input-border-color) !important',
    '&:hover': {
      borderColor: props.isFocused
        ? 'var(--input-border-color-focus)'
        : 'var(--input-border-color)',
    },
    '.error &': {
      borderColor: 'var(--input-border-color-error) !important',
    },
  }),
  menu: defaultStyles => ({
    ...defaultStyles,
    borderRadius: 'var(--radius-tiny)',
    overflow: 'hidden',
  }),
  menuList: defaultStyles => ({
    ...defaultStyles,
    padding: 0,
    margin: 0,
  }),
};

export type FormField = {
  crmFieldId?: string;
  isFieldRequired?: boolean;
} & (
  | {
      fieldType: 'textSingleLine';
      title: string;
      isGroupCategory?: never;
      withEmailValidation?: boolean;
      displayType?: never;
    }
  | {
      fieldType: 'textMultiline';
      title: string;
      isGroupCategory?: never;
      withEmailValidation?: never;
      displayType?: never;
    }
  | {
      fieldType: 'select';
      title: string;
      isGroupCategory: boolean;
      withEmailValidation?: never;
      selectOptions: Array<{
        title: string;
        crmValueStored: string;
      }>;
      displayType: 'dropdown' | 'multiCheckbox';
    }
  | {
      fieldType: 'singleCheckbox';
      title?: never;
      isGroupCategory?: never;
      _rawText: RawPortableText;
      withEmailValidation?: never;
      displayType?: never;
    }
);

export type FormFieldWithId = {
  id: string;
} & FormField;

export interface FormType {
  title?: string;
  submitButtonText?: string;
  fileToDownloadAfterSubmit?: {
    asset: {
      url: string;
    };
  };
  thankYouScreen: {
    title: string;
    _rawText?: RawPortableText;
  };
}

type FormProps = FormType & {
  _rawTitle?: RawPortableText;
  fields: Array<FormFieldWithId>;
  text?: string;
  sendDataToMailchimp?: boolean;
  withBackgroundColor?: boolean;
  hideLabels?: boolean;
  withSubmitButtonInsideInput?: boolean;
  className?: string;
};

const Form = ({
  _rawTitle,
  title,
  text,
  fields,
  fileToDownloadAfterSubmit,
  submitButtonText,
  thankYouScreen,
  sendDataToMailchimp,
  withBackgroundColor,
  hideLabels,
  withSubmitButtonInsideInput,
  className,
}: FormProps): React.ReactElement => {
  const fieldsByName = Object.fromEntries(
    fields.map(field => [
      field.id,
      field.fieldType === 'singleCheckbox'
        ? useFormField<boolean>(false, [...(field.isFieldRequired ? (['required'] as const) : [])])
        : useFormField<string>('', [
            ...(field.isFieldRequired ? (['required'] as const) : []),
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            ...(field.withEmailValidation && field.fieldType === 'textSingleLine'
              ? (['email'] as const)
              : []),
          ]),
    ]),
  );

  const dataWithoutInterests: { [key: string]: string } = Object.fromEntries(
    fields
      .filter(field => field.crmFieldId)
      .filter(field => !field.isGroupCategory)
      .map(({ id, crmFieldId }) => [crmFieldId, fieldsByName[id].value]),
  );

  const interests: { [key: string]: string } = Object.fromEntries(
    flatten(
      fields
        .filter(field => field.crmFieldId)
        .filter(field => field.isGroupCategory)
        .map(({ id, displayType }) => {
          assert(displayType === 'dropdown' || displayType === 'multiCheckbox', '');
          const value = fieldsByName[id].value;
          assert(
            typeof value === 'string',
            `displayType is ${displayType}, but value is not a string`,
          );
          if (!value) {
            return [];
          }

          if (displayType === 'dropdown') {
            return [[value, true]];
          }
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (displayType === 'multiCheckbox') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
            return JSON.parse(value).map(optionId => [optionId, true]);
          }
        }),
    ),
  );

  const { getFieldProps, renderSubmitButton, renderFormMessage, submitState, onFieldUnfocus } =
    useForm({
      fieldsByName,
      onSubmit,
      translateFunction: key => {
        return {
          'form.required_field_error': 'This field is required',
          'form.invalid_email_error': 'Invalid email (e.g. email@example.com)',
          'form.network_error': 'Network failed to send your request.',
          'form.unknown_error': 'An unexpected error occured. Please try again later.',
          'form.success_message': "Thank you for your message, we'll contact you shortly.",
        }[key];
      },
    });

  const trackingData = useTrackingData();

  async function onSubmit() {
    if (sendDataToMailchimp) {
      const mailchimpSuccess = await sendContactToMailchimp({
        data: dataWithoutInterests,
        interests,
        trackingData,
      });
      if (mailchimpSuccess) {
        if (fileToDownloadAfterSubmit) {
          window.open(fileToDownloadAfterSubmit.asset.url);
        }
      }
      return mailchimpSuccess;
    } else {
      const netlifySuccess = await sendNetlifyEmail(dataWithoutInterests);
      if (netlifySuccess) {
        if (fileToDownloadAfterSubmit) {
          window.open(fileToDownloadAfterSubmit.asset.url);
        }
      }
      return netlifySuccess;
    }
  }

  const [minFirstScreenContainerHeight, setMinFirstScreenContainerHeight] = useState<number | null>(
    null,
  );
  const firstScreenContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Warning: this code doesn't allow for the container to shrink, only grow
    function updateMinFirstScreenContainerHeight() {
      if (firstScreenContainerRef.current) {
        setMinFirstScreenContainerHeight(
          firstScreenContainerRef.current.getBoundingClientRect().height,
        );
      }
    }
    updateMinFirstScreenContainerHeight();
    window.addEventListener('resize', updateMinFirstScreenContainerHeight);
  }, []);

  return (
    <div
      className={clsx(
        styles.formContainer,
        withBackgroundColor && styles.withBackgroundColor,
        className,
      )}
      id="insight-form"
    >
      {(submitState === 'ready' || submitState === 'submitting') && (_rawTitle || text) && (
        <div className={styles.textContainer}>
          {_rawTitle && (
            <BlockContent
              blocks={_rawTitle}
              renderContainerOnSingleChild
              serializers={serializers}
            />
          )}
          {text && <p className={styles.text}>{text}</p>}
        </div>
      )}
      {(submitState === 'ready' || submitState === 'submitting') && (
        <form name="contact" data-netlify="true">
          <input type="hidden" name="form-name" value="contact" />
          <div className={styles.formScreen} ref={firstScreenContainerRef}>
            {title && <h3 className={styles.title}>{replaceNewLinesWithBr(title)}</h3>}
            <div
              className={clsx(
                styles.infoBlock,
                withSubmitButtonInsideInput && styles.withSubmitButtonInsideInput,
              )}
            >
              {fields.map((formField, i) => {
                if (formField.fieldType === 'singleCheckbox') {
                  const field = fieldsByName[formField.id] as GenericField<boolean>;
                  return (
                    <div
                      className={clsx(styles.checkboxContainer, !!field.error && styles.error)}
                      key={i}
                    >
                      <div className={styles.checkboxOptionContainer}>
                        <label htmlFor={formField.id} className={styles.checkboxLabel}>
                          <BlockContent
                            renderContainerOnSingleChild
                            blocks={formField._rawText}
                            serializers={serializers}
                            className={styles.singleCheckboxText}
                          />
                          <input
                            className={clsx(styles.checkbox)}
                            type="checkbox"
                            name={formField.crmFieldId || formField.id}
                            id={formField.id}
                            checked={!!field.value}
                            onChange={event => {
                              field.setValue(event.target.checked);
                              field.setError('');
                            }}
                            onBlur={() => {
                              onFieldUnfocus(field);
                            }}
                            tabIndex={0}
                            onKeyDown={event => {
                              if (event.key === 'Enter') {
                                event.preventDefault();
                                if (event.target && 'click' in event.target) {
                                  // @ts-expect-error
                                  event.target.click();
                                }
                              }
                            }}
                          />
                          <span className={styles.checkmark} />
                        </label>
                      </div>
                      <div
                        // eslint-disable-next-line no-extra-boolean-cast
                        className={!!field.error ? styles.helperText : ''}
                        style={
                          withSubmitButtonInsideInput
                            ? { display: !field.error ? 'none' : 'block' }
                            : { visibility: !field.error ? 'hidden' : 'visible' }
                        }
                      >
                        <FaCircleExclamation />
                        {field.error}
                      </div>
                    </div>
                  );
                }

                const field = fieldsByName[formField.id] as GenericField<string>;
                let inputElement: React.ReactElement;
                if (formField.fieldType === 'select') {
                  const selectFieldOptions = formField.selectOptions.map(selectOption => ({
                    value: selectOption.crmValueStored,
                    label: selectOption.title,
                  }));

                  if (formField.displayType === 'dropdown') {
                    inputElement = (
                      <div className={styles.selectContainer}>
                        <Select<Option>
                          inputId={formField.id + '-input'}
                          name={formField.crmFieldId || formField.id}
                          styles={customStyles}
                          options={selectFieldOptions}
                          placeholder=""
                          className={field.error ? 'error' : styles.select}
                          value={
                            selectFieldOptions.find(option => option.value === field.value) ||
                            undefined
                          }
                          onChange={currentOption => {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            field.setValue(currentOption ? currentOption.value : null);
                            field.setError('');
                          }}
                          onBlur={() => {
                            onFieldUnfocus(field);
                          }}
                          blurInputOnSelect={false}
                        />
                        <div
                          // eslint-disable-next-line no-extra-boolean-cast
                          className={!!field.error ? styles.helperText : ''}
                          style={
                            withSubmitButtonInsideInput
                              ? { display: !field.error ? 'none' : 'block' }
                              : { visibility: !field.error ? 'hidden' : 'visible' }
                          }
                        >
                          <FaCircleExclamation />
                          {field.error || 'x'}
                        </div>
                      </div>
                    );
                  } else {
                    inputElement = (
                      <div className={styles.checkboxContainer}>
                        {selectFieldOptions.map((selectOption, i) => (
                          <div className={styles.checkboxOptionContainer} key={i}>
                            <label htmlFor={selectOption.value} className={styles.checkboxLabel}>
                              <input
                                className={styles.checkbox}
                                key={i}
                                type="checkbox"
                                id={selectOption.value}
                                name={selectOption.value}
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                                checked={JSON.parse(field.value || '[]').includes(
                                  selectOption.value,
                                )}
                                onChange={event => {
                                  const currentValueArray = JSON.parse(field.value || '[]');
                                  let newValueArray;
                                  if (
                                    event.target.checked &&
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                                    !currentValueArray.includes(selectOption.value)
                                  ) {
                                    newValueArray = [...currentValueArray, selectOption.value];
                                  } else if (
                                    !event.target.checked &&
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                                    currentValueArray.includes(selectOption.value)
                                  ) {
                                    newValueArray = removeItem(
                                      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                      currentValueArray,
                                      selectOption.value,
                                    );
                                  }
                                  if (newValueArray) {
                                    field.setValue(
                                      newValueArray.length > 0 ? JSON.stringify(newValueArray) : '',
                                    );
                                    field.setError('');
                                  }
                                }}
                                onBlur={() => {
                                  setTimeout(() => {
                                    // Only run onFieldUnfocus if the element that is focused is not
                                    // another checkbox from this group, otherwise we may just be
                                    // tabbing between checkboxes.
                                    if (
                                      !selectFieldOptions.some(
                                        option => option.value === document.activeElement?.id,
                                      )
                                    ) {
                                      onFieldUnfocus(field);
                                    }
                                  }, 50);
                                }}
                                onKeyDown={event => {
                                  if (event.key === 'Enter') {
                                    event.preventDefault();
                                    if (event.target && 'click' in event.target) {
                                      // @ts-expect-error
                                      event.target.click();
                                    }
                                  }
                                }}
                              />
                              <span className={styles.checkmark} />
                              {selectOption.label}
                            </label>
                          </div>
                        ))}
                        <div
                          // eslint-disable-next-line no-extra-boolean-cast
                          className={!!field.error ? styles.helperText : ''}
                          style={
                            withSubmitButtonInsideInput
                              ? { display: !field.error ? 'none' : 'block' }
                              : { visibility: !field.error ? 'hidden' : 'visible' }
                          }
                        >
                          <FaCircleExclamation />
                          {field.error || 'x'}
                        </div>
                      </div>
                    );
                  }
                } else if (formField.fieldType === 'textSingleLine') {
                  inputElement = (
                    <InputField
                      className={clsx(styles.input, field.error && styles.error)}
                      type={formField.withEmailValidation ? 'email' : 'text'}
                      id={formField.id + '-input'}
                      name={formField.crmFieldId || formField.id}
                      helperTextClass={styles.helperText}
                      helperTextWithDisplayNone
                      {...getFieldProps(field)}
                    />
                  );
                  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                } else if (formField.fieldType === 'textMultiline') {
                  inputElement = (
                    <InputField
                      textarea
                      className={clsx(styles.input, styles.textInput, field.error && styles.error)}
                      id={formField.id + '-input'}
                      name={formField.crmFieldId || formField.id}
                      helperTextClass={styles.helperText}
                      {...getFieldProps(field)}
                    />
                  );
                } else {
                  //@ts-expect-error
                  throw new Error(`Unknown fieldType "${formField.fieldType}"`);
                }

                return (
                  <div
                    key={formField.id}
                    // eslint-disable-next-line no-extra-boolean-cast
                    className={clsx(styles.inputContainer, !!field.error ? styles.error : '')}
                  >
                    {!hideLabels && (
                      <label htmlFor={formField.id + '-input'} className={styles.label}>
                        {formField.title}
                        {formField.isFieldRequired ? '*' : ''}
                      </label>
                    )}
                    {inputElement}
                  </div>
                );
              })}
              {renderSubmitButton({
                labels: {
                  ready: submitButtonText || 'Submit',
                  submitting: 'Loading...',
                  submitted: 'Submitted',
                },
                btnClasses: {
                  common: styles.submitButton,
                  ready: styles.formReady,
                  submitting: styles.formSubmitting,
                  submitted: styles.formSubmitted,
                },
              })}
              {renderFormMessage({
                styles: {
                  formMessage: styles.formMessage,
                  formMessageSuccess: styles.formMessageSuccess,
                  formMessageError: styles.formMessageError,
                },
              })}
            </div>
          </div>
        </form>
      )}
      {submitState === 'submitted' && (
        <div
          className={styles.thankYouScreenContainer}
          style={{ minHeight: minFirstScreenContainerHeight || undefined }}
        >
          <img src={submittedIcon} />
          <h2 className={styles.thankYouTitle}>{thankYouScreen.title}</h2>
          {thankYouScreen._rawText && (
            <BlockContent
              renderContainerOnSingleChild
              className={styles.thankYouText}
              blocks={thankYouScreen._rawText}
              serializers={serializers}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Form;
