import { useGSAP } from '@gsap/react';
import { graphql } from 'gatsby';
import gsap from 'gsap';
import React, { useRef } from 'react';
import { ButtonLinkType } from '../../../graphql-fragments/ButtonLink';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import archMask from '../../../images/arch_mask.svg';
import { BREAKPOINTS } from '../../../styles/breakpoints';
import { handleCalendlyClick } from '../../../utils/projectUtils';
import { getUrlFromVersatileLink } from '../../../utils/sanity';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps } from '../../ModulesContent';
import ButtonLink from '../ButtonLink';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import WhatsappButton from '../WhatsappButton';
import * as styles from './CTAModule.module.scss';
import {
  pushCalendlyButtonClickEvent,
  pushWhatsappButtonClickEvent,
  withDataLayer,
} from '../../../tracking/dataLayer';

export const CTAModuleFragment = graphql`
  fragment CTAModule on SanityPageCtaModule {
    title {
      ...LocaleStringFragment
    }
    text {
      ...LocaleTextFragment
    }
    button {
      ...ButtonLink
    }
    addWhatsappButton
    backgroundImage {
      ...SanityImage
    }
  }
`;

export type CTAModuleProps = {
  title: string;
  text: string;
  button: ButtonLinkType;
  addWhatsappButton?: boolean;
  backgroundImage?: SanityImageType;
  calendlyLink: string;
  className?: string;
};

function CTAModule(props: CTAModuleProps & CommonModuleProps): React.ReactElement {
  const {
    title,
    text,
    backgroundImage,
    button,
    calendlyLink,
    addWhatsappButton,
    className,
    moduleId,
  } = props;
  const containerRef = useRef<HTMLElement | null>(null);

  useGSAP(
    () => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: `.${styles.contentWrapper}`,
            start: backgroundImage ? 'top 75%' : 'top 90%',
          } satisfies ScrollTrigger.Vars,
        })
        // animate text
        .from(`.${styles.title}, .${styles.text}, .${styles.buttonWrapper}`, {
          opacity: 0,
          y: -20,
          stagger: 0.2,
        });

      gsap
        // image parallax
        .fromTo(
          '.' + styles.backgroundImage,
          {
            y: '-20%',
            transform: 'scale(1.2)',
            ease: 'none',
          },
          {
            y: '0%',
            scrollTrigger: {
              start: 'top bottom',
              end: 'bottom top',
              trigger: '.' + styles.backgroundImageContainer,
              scrub: true,
            } satisfies ScrollTrigger.Vars,
          },
        );
    },
    { scope: containerRef },
  );
  return (
    <ModuleLayout
      id={moduleId}
      moduleRef={containerRef}
      className={clsx(className, styles.container, backgroundImage && styles.withBackgroundImage)}
      contentClassName={clsx(styles.contentContainer)}
    >
      {backgroundImage && (
        <div className={styles.backgroundImageContainer}>
          <Image
            image={backgroundImage}
            className={styles.backgroundImage}
            dimensions={[
              [450, 850],
              [BREAKPOINTS.tabletVertical, 800, 850],
              [BREAKPOINTS.smallLaptop, 2880, 1920],
            ]}
            cover
          />
          <div className={styles.overlay}>
            <div className={styles.overlayFullSideAbove}></div>
            <div className={styles.overlayRow}>
              <div className={styles.overlayFullSideBeside}></div>
              <div
                className={styles.overlayHoleSide}
                style={{ maskImage: `linear-gradient(#fff,#fff), url(${archMask})` }}
              ></div>
            </div>
          </div>
        </div>
      )}
      <div className={clsx(styles.contentWrapper)}>
        <div className={clsx(styles.textContainer)}>
          <h2 className={clsx(styles.title)}>{title}</h2>
          <p className={clsx(styles.text)}>{text}</p>
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonWrapper}>
              <ButtonLink
                to={getUrlFromVersatileLink(button)}
                onClick={() => {
                  if (button.externalLink?.linkToCalendly) {
                    handleCalendlyClick(calendlyLink);
                    withDataLayer(
                      pushCalendlyButtonClickEvent({ location: 'ctaModule', moduleId }),
                    );
                  }
                }}
                secondary
                withCalendarIcon={!!button.externalLink?.linkToCalendly}
                className={clsx(styles.button)}
              >
                {button.text}
              </ButtonLink>
            </div>
            {addWhatsappButton && (
              <div className={styles.buttonWrapper}>
                <WhatsappButton
                  className={styles.button}
                  onClick={() => {
                    withDataLayer(
                      pushWhatsappButtonClickEvent({ location: 'ctaModule', moduleId }),
                    );
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </ModuleLayout>
  );
}

export default CTAModule;
