import { useGSAP } from '@gsap/react';
import { graphql } from 'gatsby';
import gsap from 'gsap';
import { t } from 'i18next';
import React, { useRef, useState } from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import TranscriptIcon from '../../../images/transcript.inline.svg';
import { BREAKPOINTS } from '../../../styles/breakpoints';
import { clsx, replaceNewLinesWithBr } from '../../../utils/utils';
import { CommonModuleProps } from '../../ModulesContent';
import Image from '../Image';
import ModalOverlay from '../ModalOverlay';
import ModuleLayout from '../ModuleLayout';
import Video from '../Video';
import * as styles from './VideoModule.module.scss';

export const VideoModuleFragment = graphql`
  fragment VideoModule on SanityPageVideoModule {
    title {
      ...LocaleStringFragment
    }
    text {
      ...LocaleTextFragment
    }
    video {
      url {
        ...LocaleUrlFragment
      }
      file {
        ...LocaleFileFragment
      }
      thumbnail {
        ...LocaleImageFragment
      }
      videoTranscription {
        ...LocaleTextFragment
      }
      videoTitle {
        ...LocaleStringFragment
      }
      videoSubtitle {
        ...LocaleStringFragment
      }
    }
    mobileBackgroundImage {
      ...SanityImage
      ...SanityImage
    }
    desktopBackgroundImage {
      ...SanityImage
    }
  }
`;

type VideoAsset =
  | {
      url: string;
      file?: never;
      thumbnail?: {
        asset: {
          _id: string;
          url: string;
        };
      };
    }
  | {
      url?: never;
      file: {
        asset: {
          url: string;
        };
      };
      thumbnail: {
        asset: {
          _id: string;
          url: string;
        };
      };
    };

export type VideoModuleProps = {
  title: string;
  text: string;
  video: VideoAsset & {
    videoTranscription?: string;
    videoTitle?: string;
    videoSubtitle?: string;
  };
  mobileBackgroundImage: SanityImageType;
  desktopBackgroundImage: SanityImageType;
  className?: string;
};

function VideoModule(props: VideoModuleProps & CommonModuleProps): React.ReactElement {
  const { title, text, video, mobileBackgroundImage, desktopBackgroundImage, className, moduleId } =
    props;

  const [isVideoTranscriptionModalOpen, setIsVideoTranscriptionModalOpen] = useState(false);

  const containerRef = useRef<HTMLElement | null>(null);

  useGSAP(
    () => {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: `.${styles.contentWrapper}`,
            start: 'top 90%',
          } satisfies ScrollTrigger.Vars,
        })
        // animate text
        .from(`.${styles.title}, .${styles.text}`, {
          opacity: 0,
          y: -20,
          stagger: 0.2,
        })
        .from(
          `.${styles.videoContainer}`,
          {
            y: -80,
            opacity: 0,
            duration: 2,
          },
          '>-0.5',
        )
        .from(
          `.${styles.link}`,
          {
            y: -16,
            opacity: 0,
            duration: 1,
            stagger: 0.2,
          },
          '-=0.4',
        );

      // image parallax
      gsap.fromTo(
        `.${styles.backgroundImageContainer}`,
        {
          y: '-20%',
          transform: 'scale(1.2)',
          ease: 'none',
        },
        {
          y: '20%',
          scrollTrigger: {
            trigger: `.${styles.contentContainer}`,
            start: 'top bottom',
            end: 'bottom top',
            scrub: true,
          } satisfies ScrollTrigger.Vars,
        },
      );
    },
    { scope: containerRef },
  );

  return (
    <ModuleLayout
      id={moduleId}
      className={clsx(className, styles.container)}
      contentClassName={clsx(styles.contentContainer)}
      moduleRef={containerRef}
    >
      <div className={styles.backgroundImageContainer}>
        <Image
          className={styles.backgroundImage}
          image={mobileBackgroundImage}
          dimensions={[
            [520, 1080],
            [BREAKPOINTS.tabletVertical, desktopBackgroundImage, 1920, 1080],
          ]}
          cover
        />
        <div className={styles.overlay}></div>
      </div>
      <div className={clsx(styles.contentWrapper)}>
        <div className={clsx(styles.textContainer)}>
          <h2 className={clsx(styles.title)}>{title}</h2>
          <p className={styles.text}>{text}</p>
        </div>
        <div className={styles.videoContainer}>
          <Video
            url={video.file ? video.file.asset.url : video.url}
            className={styles.video}
            thumbnail={video.thumbnail}
          />
          {video.videoTranscription && (
            <>
              <button
                onClick={() => {
                  setIsVideoTranscriptionModalOpen(true);
                }}
                className={styles.link}
              >
                {t('hero.button_video_transcript', 'Ver transcrição do vídeo')}{' '}
                <TranscriptIcon className={styles.linkIcon} />
              </button>
              <ModalOverlay
                onClose={() => {
                  setIsVideoTranscriptionModalOpen(false);
                }}
                open={isVideoTranscriptionModalOpen}
                title={
                  <div className={styles.modalTitleContainer}>
                    <span className={styles.videoTranscriptionSupertitle}>
                      <TranscriptIcon className={styles.modalIcon} />{' '}
                      {t('hero.video_transcription_modal_supertitle', 'Transcrição de vídeo')}
                    </span>
                    {video.videoTitle && (
                      <h2 className={styles.modalVideoTitle}>{video.videoTitle}</h2>
                    )}
                    {video.videoSubtitle && (
                      <p className={styles.modalVideoSubtitle}>{video.videoSubtitle}</p>
                    )}
                  </div>
                }
              >
                <div className={styles.videoTranscriptionContainer}>
                  <p className={styles.videoTranscription}>
                    {replaceNewLinesWithBr(video.videoTranscription)}
                  </p>
                </div>
              </ModalOverlay>
            </>
          )}
        </div>
      </div>
    </ModuleLayout>
  );
}

export default VideoModule;
