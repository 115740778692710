/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';

import { AUTHOR_NAME, COPYRIGHT_DEFAULT_YEAR } from '../../constants';
import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import { BackgroundColorTheme } from '../../templates/Page';
import { getUrlFromVersatileLink } from '../../utils/sanity';
import { clsx, urlJoin } from '../../utils/utils';
import * as styles from './Footer.module.scss';
import SmartLink from './SmartLink';
import TextLink from './TextLink';

type SocialNetworkIconName = 'facebook' | 'linkedin' | 'twitter' | 'instagram';
export type SocialNetworksBlock = {
  title?: string;
  socialNetworks?: Array<{
    iconName: SocialNetworkIconName;
    url?: string;
  }>;
};

type CtaBlock = {
  title?: string;
  ctaButton?: ButtonLinkType;
};

export type NewsletterBlock = {
  title: string;
  buttonText: string;
  emailId: string;
  onNewsletterSubscribe?: (event: React.MouseEvent) => void;
};

export interface FooterProps {
  logoImage: string;
  theme?: BackgroundColorTheme;
  companyDescription?: string;
  socialNetworksBlock?: SocialNetworksBlock;
  legalDocsBlock?: {
    title: string;
    docs: Array<{
      title: string;
      slug: {
        current: string;
      };
    }>;
  };
  ctaBlock?: CtaBlock;
  contacts?: {
    title: string;
    address?: string;
    email?: string;
    phoneNumbers?: Array<string>;
    whatsAppNumber?: string;
  };
  newsletterBlock?: NewsletterBlock;
  policyLinks?: Array<ButtonLinkType>;
  marginlessFooter?: boolean;
}

const Footer = ({
  logoImage,
  theme,
  companyDescription,
  ctaBlock,
  newsletterBlock,
  legalDocsBlock,
  contacts,
  policyLinks,
  socialNetworksBlock,
  marginlessFooter,
}: FooterProps): React.ReactElement => {
  const { t } = useTranslation();
  const [currentYear, setCurrentYear] = useState<number>(COPYRIGHT_DEFAULT_YEAR);

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  function renderLogo(logoImage: string) {
    return (
      <div className={styles.logoContainer}>
        <img src={logoImage} className={styles.logo} height={60} alt="logo" />
      </div>
    );
  }

  function renderCopyright() {
    return (
      <div className={styles.copyright}>
        <span>
          {'Copyright ' + currentYear} | {'All rights reserved to ' + AUTHOR_NAME}
        </span>
        <span className={styles.poweredBy}>
          Powered by{' '}
          <TextLink
            to={
              'https://www.digitalspiders.io/?utm_source=client_website&utm_medium=footer&utm_campaign=heed'
            }
            withExternalIcon={false}
          >
            Digital Spiders
          </TextLink>
        </span>
      </div>
    );
  }

  function renderSocialMedia(title: string) {
    const socialMediaConfig = {
      facebook: { title: 'Facebook', icon: FaFacebook },
      linkedin: { title: 'Linkedin', icon: FaLinkedinIn },
      twitter: { title: 'Twitter', icon: FaTwitter },
      instagram: { title: 'Instagram', icon: FaInstagram },
    };
    const Icon = socialMediaConfig[title].icon;
    return (
      <>
        <Icon className={styles.icon} />
        <span>{socialMediaConfig[title].title}</span>
        <div className={styles.visuallyHidden}>
          Visit {AUTHOR_NAME} {title}
        </div>
      </>
    );
  }

  const withSocialNetworksBlock = !!(
    socialNetworksBlock &&
    socialNetworksBlock.socialNetworks &&
    socialNetworksBlock.socialNetworks?.length > 0
  );

  const withCtaBlock = !!(ctaBlock && ctaBlock.title && ctaBlock.ctaButton);

  const isFooterComplex = !!(
    companyDescription ||
    withCtaBlock ||
    newsletterBlock ||
    (policyLinks && policyLinks.length > 0) ||
    withSocialNetworksBlock
  );

  return (
    <footer
      className={clsx(styles.footer, marginlessFooter && styles.marginlessFooter)}
      data-theme={'light'}
    >
      {isFooterComplex ? (
        <div className={styles.footerContainer}>
          <div className={styles.footerWrapper}>
            <div className={styles.infoContainer}>
              {renderLogo(logoImage)}
              {companyDescription && (
                <div className={styles.companyDescription}>{companyDescription}</div>
              )}
              {(legalDocsBlock?.docs || contacts || socialNetworksBlock) && (
                <div className={styles.columnsContainer}>
                  {legalDocsBlock?.docs && (
                    <div className={styles.columnContainer}>
                      <span className={styles.columnTitle}>{legalDocsBlock.title}</span>
                      {legalDocsBlock.docs.map((doc, i) => (
                        <TextLink
                          to={urlJoin('legalDocumentation', doc.slug.current)}
                          key={i}
                          className={styles.link}
                        >
                          {doc.title}
                        </TextLink>
                      ))}
                      <TextLink
                        to="https://heedcap.integrityline.com/setup"
                        className={clsx(styles.link, styles.externalLink)}
                      >
                        {t('footer.whistleblowing_link', 'Canal de Denúncias')}
                      </TextLink>
                    </div>
                  )}
                  {socialNetworksBlock?.socialNetworks && (
                    <div className={styles.columnContainer}>
                      <span className={styles.columnTitle}>{socialNetworksBlock.title}</span>
                      <div className={styles.socialMediaContainer}>
                        {socialNetworksBlock.socialNetworks?.map(
                          (socialNetwork, i) =>
                            socialNetwork.url && (
                              <SmartLink key={i} to={socialNetwork.url} className={styles.link}>
                                {renderSocialMedia(socialNetwork.iconName)}
                              </SmartLink>
                            ),
                        )}
                      </div>
                    </div>
                  )}
                  {contacts && (
                    <div className={styles.columnContainer}>
                      <span className={styles.columnTitle}>{contacts.title}</span>
                      {contacts.address && (
                        <SmartLink
                          to={'https://www.google.com/maps/place/' + encodeURI(contacts.address)}
                          className={styles.link}
                        >
                          <FaMapMarkerAlt className={styles.contactIcon}></FaMapMarkerAlt>
                          <span>{contacts.address}</span>
                        </SmartLink>
                      )}
                      {contacts.email && (
                        <SmartLink to={'mailto:' + contacts.email} className={styles.link}>
                          <FaEnvelope className={styles.contactIcon}></FaEnvelope>
                          <span>{contacts.email}</span>
                        </SmartLink>
                      )}
                      {contacts.phoneNumbers &&
                        contacts.phoneNumbers.length > 0 &&
                        contacts.phoneNumbers?.map((phoneNumber, i) => (
                          <SmartLink key={i} to={'tel:' + phoneNumber} className={styles.link}>
                            <FaPhoneAlt className={styles.contactIcon}></FaPhoneAlt>
                            <span>{phoneNumber}</span>
                          </SmartLink>
                        ))}
                      {contacts.whatsAppNumber && (
                        <SmartLink
                          to={'https://wa.me/' + contacts.whatsAppNumber.replace(/[^\d]/g, '')}
                          className={styles.whatsappContainer}
                        >
                          <FaWhatsapp className={styles.contactIcon} />
                          <span>{t('footer.whatsapp_banner', 'Fale connosco')}</span>
                        </SmartLink>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={styles.legalContainer}>
            {policyLinks && policyLinks.length > 0 && (
              <div className={styles.policyLinksContainer}>
                {policyLinks.map((link, i) => (
                  <TextLink to={getUrlFromVersatileLink(link)} key={i} className={styles.link}>
                    {link.text}
                  </TextLink>
                ))}
              </div>
            )}
            {renderCopyright()}
          </div>
        </div>
      ) : (
        <div className={clsx(styles.footerContainer, styles.simpleFooter)}>{renderCopyright()}</div>
      )}
    </footer>
  );
};

export default Footer;
