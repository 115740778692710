/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useGSAP } from '@gsap/react';
import { graphql } from 'gatsby';
import gsap from 'gsap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaDownload } from 'react-icons/fa';
import { ButtonLinkType } from '../../graphql-fragments/ButtonLink';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import TranscriptIcon from '../../images/transcript.inline.svg';
import { BREAKPOINTS } from '../../styles/breakpoints';
import { BackgroundColorTheme } from '../../templates/Page';
import {
  pushCalendlyButtonClickEvent,
  pushWhatsappButtonClickEvent,
  withDataLayer,
} from '../../tracking/dataLayer';
import { uniqBy } from '../../utils/nodash';
import { handleCalendlyClick } from '../../utils/projectUtils';
import { getPortableTextAsString, getUrlFromVersatileLink } from '../../utils/sanity';
import { clsx, replaceNewLinesWithBr, slugify } from '../../utils/utils';
import ButtonLink from './ButtonLink';
import Form, { FormField, FormFieldWithId, FormType } from './Form';
import * as styles from './Hero.module.scss';
import Image from './Image';
import ModalOverlay from './ModalOverlay';
import ModuleLayout from './ModuleLayout';
import TextLink from './TextLink';
import Video from './Video';
import WhatsappButton from './WhatsappButton';

export const query = graphql`
  fragment Hero on SanityPageHero {
    title {
      ...LocaleStringFragment
    }
    subtitle {
      ...LocaleStringFragment
    }
    text {
      ...LocaleTextFragment
    }
    videoUrl {
      ...LocaleUrlFragment
    }
    videoTranscription {
      ...LocaleTextFragment
    }
    videoTitle {
      ...LocaleStringFragment
    }
    videoSubtitle {
      ...LocaleStringFragment
    }
    brochure {
      ...LocaleFileFragment
    }
    brochureUrl {
      ...LocaleStringFragment
    }
    mobileBackgroundImage {
      ...SanityImage
    }
    desktopBackgroundImage {
      ...SanityImage
    }
    addWhatsappButton
  }
`;

export type HeroType = {
  title: string;
  text?: string;
  buttonLink?: ButtonLinkType;
  videoUrl?: string;
  videoTranscription?: string;
  videoTitle?: string;
  videoSubtitle?: string;
  brochureUrl?: string;
  addWhatsappButton?: boolean;
} & (
  | {
      heroType: 'heroWithImage';
      sideOptionslayout: 'textLeft' | 'textRight';
      imageType: 'background' | 'besidesText';
      mobileImage: SanityImageType;
      desktopImage: SanityImageType;
      form?: never;
    }
  | {
      heroType: 'onlyText';
      sideOptionslayout?: never;
      imageType?: never;
      mobileImage?: never;
      desktopImage?: never;
      form?: never;
    }
  | {
      heroType: 'heroWithForm';
      sideOptionslayout?: never;
      imageType?: never;
      mobileImage?: never;
      desktopImage?: never;
      form: FormType & {
        fields: Array<FormField>;
      };
    }
);

type HeroProps = HeroType & {
  theme?: BackgroundColorTheme;
  subtitle?: string;
  heroRef: React.MutableRefObject<HTMLElement | null>;
  calendlyLink: string;
  className?: string;
};

const Hero = ({
  theme,
  heroRef,
  heroType,
  sideOptionslayout,
  imageType,
  title,
  subtitle,
  text,
  buttonLink,
  mobileImage,
  desktopImage,
  videoUrl,
  videoTranscription,
  videoTitle,
  videoSubtitle,
  brochureUrl,
  form,
  calendlyLink,
  addWhatsappButton,
}: HeroProps): React.ReactElement => {
  const { t } = useTranslation();

  const [isVideoTranscriptionModalOpen, setIsVideoTranscriptionModalOpen] = useState(false);

  const formFieldsWithIds: Array<FormFieldWithId> = form
    ? form.fields.map(formField => ({
        id: slugify(
          formField.fieldType === 'singleCheckbox'
            ? getPortableTextAsString(formField._rawText)
            : formField.title,
        ),
        ...formField,
      }))
    : [];

  if (formFieldsWithIds!.length !== uniqBy(formFieldsWithIds!, 'id').length) {
    throw new Error(
      'Got duplicate ids in formFieldsWithIds: ' +
        JSON.stringify(formFieldsWithIds!.map(formField => formField.id)),
    );
  }

  useGSAP(
    () => {
      gsap
        .timeline({})
        .from(`.${styles.title}, .${styles.subtitle}, .${styles.text}, .${styles.buttonWrapper}`, {
          y: -50,
          autoAlpha: 0,
          delay: 1,
          duration: 1,
          stagger: 0.2,
        })
        .from(
          '.' + styles.video,
          {
            x: '60%',
            autoAlpha: 0,
            duration: 0.6,
          },
          '-=0.8',
        )
        .from(
          '.' + styles.link,
          {
            y: -50,
            autoAlpha: 0,
            duration: 1,
            stagger: 0.2,
          },
          '-=0.4',
        );

      // image parallax
      gsap.fromTo(
        '.' + styles.backgroundImageContainer,
        {
          transform: 'scale(1.1)',
          ease: 'none',
        },
        {
          y: '20%',
          scrollTrigger: {
            trigger: '.' + styles.contentContainer,
            start: 'top top',
            end: 'bottom top',
            scrub: true,
          } satisfies ScrollTrigger.Vars,
        },
      );
    },
    { scope: heroRef },
  );

  return (
    <ModuleLayout
      moduleRef={heroRef}
      className={clsx(
        styles.container,
        heroType === 'onlyText' && styles.onlyText,
        heroType === 'heroWithImage' && imageType === 'besidesText' && styles.imageBesides,
        heroType === 'heroWithForm' && styles.withForm,
        heroType === 'heroWithImage' && imageType === 'background' && styles.withBackgroundImage,
      )}
      contentClassName={clsx(styles.contentContainer)}
      childrenWithoutHorizontalPadding={heroType === 'heroWithImage' && imageType === 'besidesText'}
    >
      {heroType === 'heroWithImage' && imageType === 'background' && (
        <div className={styles.backgroundImageContainer}>
          <Image
            className={styles.backgroundImage}
            image={mobileImage}
            dimensions={[
              [520, 1080],
              [BREAKPOINTS.laptop, desktopImage, 1920, 1080],
            ]}
          />
          <div className={styles.overlay}></div>
        </div>
      )}
      <div
        className={clsx(
          styles.contentWrapper,
          heroType === 'heroWithImage' && sideOptionslayout === 'textRight' && styles.textRight,
        )}
      >
        <div className={clsx(styles.textContainer)}>
          <h1 className={clsx(styles.title)}>{title}</h1>
          {subtitle && <p className={clsx(styles.subtitle)}>{subtitle}</p>}
          {text && <p className={clsx(styles.text)}>{text}</p>}
          {(buttonLink || addWhatsappButton) && (
            <div className={styles.buttonsContainer}>
              {buttonLink && (
                <div className={styles.buttonWrapper}>
                  <ButtonLink
                    className={clsx(styles.button)}
                    to={getUrlFromVersatileLink(buttonLink)}
                    onClick={() => {
                      if (buttonLink.externalLink?.linkToCalendly) {
                        handleCalendlyClick(calendlyLink);
                        withDataLayer(pushCalendlyButtonClickEvent({ location: 'hero' }));
                      }
                    }}
                    withCalendarIcon={!!buttonLink.externalLink?.linkToCalendly}
                  >
                    {buttonLink.text}
                  </ButtonLink>
                </div>
              )}
              {addWhatsappButton && (
                <div className={styles.buttonWrapper}>
                  <WhatsappButton
                    className={styles.button}
                    onClick={() => {
                      withDataLayer(pushWhatsappButtonClickEvent({ location: 'hero' }));
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {heroType === 'heroWithImage' && imageType === 'besidesText' && (
          <div className={styles.imageContainer}>
            <Image
              className={styles.backgroundImage}
              image={mobileImage}
              dimensions={[
                [520, 1080],
                [BREAKPOINTS.largeDesktop, desktopImage, 1920, 1080],
              ]}
            />
          </div>
        )}
        {(videoUrl || brochureUrl) && (
          <div className={styles.videoAndLinksContainer}>
            {videoUrl && <Video className={styles.video} url={videoUrl} />}
            {(videoTranscription || brochureUrl) && (
              <div className={styles.linksContainer}>
                {videoUrl && videoTranscription && (
                  <button
                    onClick={() => {
                      setIsVideoTranscriptionModalOpen(true);
                    }}
                    className={styles.link}
                  >
                    {t('hero.button_video_transcript', 'Ver transcrição do vídeo')}{' '}
                    <TranscriptIcon className={styles.linkIcon} />
                  </button>
                )}
                {brochureUrl && (
                  <TextLink to={brochureUrl} className={styles.link} withExternalIcon={false}>
                    {t('hero.button_download_brochure', 'Descarregar Brochura')}
                    <FaDownload className={styles.linkIcon} />
                  </TextLink>
                )}
              </div>
            )}
            {videoTranscription && (
              <ModalOverlay
                onClose={() => {
                  setIsVideoTranscriptionModalOpen(false);
                }}
                open={isVideoTranscriptionModalOpen}
                title={
                  <div className={styles.modalTitleContainer}>
                    <span className={styles.videoTranscriptionSupertitle}>
                      <TranscriptIcon className={styles.modalIcon} />{' '}
                      {t('hero.video_transcription_modal_supertitle', 'Transcrição de vídeo')}
                    </span>
                    {videoTitle && <h2 className={styles.modalVideoTitle}>{videoTitle}</h2>}
                    {videoSubtitle && <p className={styles.modalVideoSubtitle}>{videoSubtitle}</p>}
                  </div>
                }
              >
                <div className={styles.videoTranscriptionContainer}>
                  <p className={styles.videoTranscription}>
                    {replaceNewLinesWithBr(videoTranscription)}
                  </p>
                </div>
              </ModalOverlay>
            )}
          </div>
        )}
        {heroType === 'heroWithForm' && form && (
          <Form {...form} fields={formFieldsWithIds} withBackgroundColor className={styles.form} />
        )}
      </div>
    </ModuleLayout>
  );
};

export default Hero;
