import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

import { checkIsInternalUrl, clsx } from '../../utils/utils';
import SmartLink, { SmartLinkProps } from './SmartLink';
import * as styles from './TextLink.module.scss';

export type TextLinkProps = Omit<SmartLinkProps, 'to' | 'openInNewTab'> &
  (
    | {
        styleOnly: true;
        withExternalIcon?: boolean;
        to?: never;
        openInNewTab?: never;
      }
    | {
        styleOnly?: never;
        withExternalIcon?: boolean;
        to: string;
        openInNewTab?: boolean;
      }
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default React.forwardRef<any, TextLinkProps>(function TextLink(
  { openInNewTab, styleOnly, className, children, to, withExternalIcon = true, ...restProps },
  ref,
): React.ReactElement {
  if (styleOnly) {
    return (
      <span className={clsx(styles.link, className)} {...restProps}>
        {children}
        {withExternalIcon && <FaExternalLinkAlt className={styles.iconRight} />}
      </span>
    );
  } else {
    const isLinkInternal = checkIsInternalUrl(to);

    return (
      <SmartLink
        openInNewTab={openInNewTab}
        ref={ref}
        to={to}
        className={clsx(styles.link, className)}
        {...restProps}
      >
        {children}
        {!isLinkInternal && (
          <FaExternalLinkAlt
            className={clsx(styles.iconRight, !withExternalIcon && styles.iconHidden)}
          />
        )}
      </SmartLink>
    );
  }
});
